// Inputs
// .p-field > label {
//   display: block;
// }
// .p-field > small {
//   display: block;
// }
label {
  cursor: inherit;
}

.p-inputtext,
.p-dropdown,
.p-autocomplete {
  width: 100%;
}
.p-field > label {
  display: inline-block;
  margin-bottom: 0.5rem;
  color: #969696;
}

// Spinner
.spinner {
  .ui-progress-spinner .ui-progress-spinner-svg .ui-progress-spinner-circle {
    animation: ui-progress-spinner-dash 1.5s ease-in-out infinite;
  }
}

// Dialog
.p-dialog.p-confirm-dialog .p-confirm-dialog-message {
  margin-left: 0;
}
.responsive-dialog {
  width: 50vw;
}
.p-dialog .p-dialog-header .p-dialog-title {
  font-size: 1.25rem;
}

@media screen and (max-width: 70em) {
  .responsive-dialog {
    width: 75vw;
  }
}
@media screen and (max-width: 40em) {
  .responsive-dialog {
    width: 100vw;
  }
}

// Table

.p-datatable .p-datatable-tbody > tr.has-discount {
  background: #f7d7d7;
}
.p-datatable .p-datatable-tbody > tr.has-extra-fee {
  background: #daf7d7;
}

@media screen and (max-width: 40em) {
  .p-datatable.p-datatable-responsive
    .p-datatable-tbody
    > tr
    > td
    .ui-column-title {
    padding: 0.4em;
    min-width: 33%;
    display: inline-block;
    margin: -0.4em 1em -0.4em -0.4em;
    font-weight: bold;
  }
  .p-datatable.p-datatable-responsive .p-datatable-tbody > tr > td {
    padding: 0.35em 1em;
    font-size: 12px;
  }
  .p-datatable .p-datatable-tbody > tr {
    border-top: 1px solid #e9ecef;
  }
}
@media screen and (min-width: 40em) {
  .p-datatable.p-datatable-responsive
    .p-datatable-tbody
    > tr
    > td
    .ui-column-title {
    display: none;
  }
}
.p-paginator .p-dropdown {
  height: 36px;
  line-height: 1;
  font-size: 0.875em;
}

// Calendar
.p-calendar {
  width: 100%;
  .p-datepicker {
    min-width: inherit !important;
  }
}

// Buttons
.p-splitbutton {
  .p-splitbutton-defaultbutton {
    border-right: 1px solid white !important;
    padding-left: 3.5rem;
    padding-right: 3.25rem;
  }
}
.p-button.p-button-sm .p-button-icon {
  font-size: 1rem;
}
.p-button-icon.p-button-icon-left.far {
  &.far,
  &.fal {
    position: relative;
    top: 1px;
  }
}

fieldset {
  display: block;
  margin: unset;
  padding: unset;
  border: unset;
}

// Menu
.p-menu .p-menuitem-link .p-menuitem-icon {
  margin-top: 1px;
  font-size: 1rem;
  width: 20px;
  text-align: center;
}

// Icons
.fa-ellipsis-v {
  position: relative;
  top: 2px;
}

.p-toast-detail {
  word-break: break-all;
}
