/* Customizations to the designer theme should be defined here */
body {
  background-color: #edf1f5;
  font-family: "Open Sans", sans-serif;
  margin: 0;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: inherit;
  margin-top: inherit;
}
h1 {
  font-size: 1.75rem;
}
h2 {
  font-size: 1.5rem;
}
h3 {
  font-size: 1.15rem;
}
hr {
  border-top: 1px solid #ebebeb;
  border-bottom: none;
}

a {
  text-decoration: none;
  color: var(--primary-color);
  &:hover {
    text-decoration: underline;
  }
}

.panel {
  background: white;
  border-radius: 8px;
  padding: 2em;
  box-shadow: 0 9px 13px rgba(0, 0, 0, 0.05);
  &.less-padding {
    padding: 1rem 1.5rem;
  }
}
p-card.less-padding .p-card-body {
  padding: 0.5rem 1.5rem;
}
.p-card {
  position: relative;
  hr {
    margin: 1.5rem -1.5rem;
  }
}

.site {
  // margin-left: 80px;
  margin-left: auto;
  margin-right: auto;
  margin-top: $header-height + $sub-header-height + 20px;
  // max-width: 1140px;
  max-width: 1000px;
  @media screen and (max-width: 1330px) {
    margin-left: 95px;
    margin-right: inherit;
  }
  @media screen and (max-width: 768px) {
    margin-left: 0;
    margin-bottom: 70px;
  }
}

.header {
  height: $header-height;
  width: 100%;
  left: 0;
  top: 0;
  background: white;
  position: fixed;
  box-shadow: 0 9px 13px rgba(0, 0, 0, 0.05);
  z-index: 2;
  padding: 14px 25px 14px 95px;

  @media screen and (max-width: 768px) {
    padding-left: 25px;
  }

  .logo {
    height: 42px;
    img {
      height: 42px;
    }
  }
}
.sub-header {
  background: var(--primary-color);
  min-height: $sub-header-height;
  position: fixed;
  top: $header-height;
  left: 0;
  width: 100%;
  z-index: 1;
  box-shadow: 0 9px 13px rgba(0, 0, 0, 0.05);
  .container {
    padding: 0 10px;
    margin-left: $left-nav-width;

    margin-left: auto;
    margin-right: auto;
    width: 1000px;
    // max-width: 1000px;

    @media screen and (max-width: 1330px) {
      margin-left: 95px;
      margin-right: inherit;
    }
    @media screen and (max-width: 768px) {
      margin-left: 0;
    }

    h3 {
      margin: 0;
      color: white;
      font-size: 1rem;
      // padding: 6px 0 6px 30px;
    }
    .p-button.p-button-sm {
      font-size: 1rem;
    }
    .p-button.p-button-sm,
    .p-button {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
    }
    .p-splitbutton .p-splitbutton-defaultbutton {
      padding-left: 2.5rem;
      padding-right: 2.25rem;
    }
  }
}
.btn-profile {
  padding: 0;
  height: 42px;
  width: 42px;
  font-weight: 600;
  font-size: 0.875em;
  line-height: 42px;
}

.left-nav {
  display: flex;
  height: 100vh;
  width: $left-nav-width;
  background: white;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  box-shadow: 0 9px 13px rgba(0, 0, 0, 0.05);
  .items {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 0.5rem;
    .p-button {
      margin: 0.75rem 0;
      font-size: 1.35rem;
      &.p-button-text {
        color: var(--color-light-grey);
        &.p-button-plain {
          color: var(--primary-color);
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .left-nav {
    z-index: 99;
    bottom: 0;
    top: inherit;
    width: 100%;
    height: 70px;
    box-shadow: 0 -3px 8px rgb(0 0 0 / 0.07);
    .items {
      justify-content: space-between;
      flex-direction: row;
      padding: 0 2em;
      width: 100%;
    }
  }
}

.info-label {
  margin: 0.5em 0;
  label {
    text-transform: uppercase;
    color: var(--color-light-grey);
    font-size: 0.9em;
    display: block;
  }
}

.breadcrumb {
  .p-button {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    padding-left: 0.5rem;
  }
  .p-button-label {
    font-size: 1.5em;
    color: initial;
  }
  .p-button .p-button-icon-left {
    margin-right: 1rem;
  }
}

.p-button:disabled {
  opacity: 0.4;
}

.p-button.p-button-white,
.p-buttonset.p-button-white > .p-button,
.p-splitbutton.p-button-white > .p-button {
  background: white;
  // color: var(--primary-color);
  color: #31363b;
  box-shadow: 0px 1px 2px -1px #0000009e;
  &:enabled:hover {
    color: var(--primary-color);
    background: white;
    // background: #c2e2ff;
  }
  &:disabled,
  &.p-disabled {
    opacity: 0.5;
  }
}

.work-order-dialog {
  width: 100%;
  max-width: 1000px;
}

.p-grid.p-d-flex.p-ai-center.quote-preview {
  &:hover {
    cursor: pointer;
  }
  background: #f5f7f9;
  border-radius: 8px;
  padding: 0 0.5em;
  margin: 0.5em 0;
  font-size: 0.8rem;
  &.has-discount {
    background: #f7d7d7;
  }
  &.has-extra-fee {
    background: #daf7d7;
  }
  strong {
    font-weight: 600;
  }
  .p-button.p-button-icon-only.p-button-rounded {
    height: 1.675rem;
    width: 1.675rem;
  }
}

.p-panel-title {
  font-size: 2em;
}
.p-button.p-button-icon-only.p-button-rounded.button-add {
  width: 3.5rem;
  border-radius: 20px;
  height: 2rem;
  font-size: 1rem;
  line-height: 1;
  .pi {
    font-size: 0.85rem;
    font-weight: bold;
  }
}
.p-button .p-badge {
  position: absolute;
  top: 0;
  display: block;
  border-radius: 999px;
  margin: 0;
  right: 0;
}
.ui-dialog-footer,
.p-dialog .p-dialog-footer,
.ui-form-footer {
  position: absolute;
  margin: 1.5rem -1.5rem -1.5rem -1.5rem;
  padding: 1rem 1.5rem;
  box-shadow: 0 -3px 13px rgba(0, 0, 0, 0.075);
  bottom: 1.5rem;
  z-index: 2;
  width: 100%;
  background: white;
  height: 80px;
}
// .ui-dialog-footer,
// .p-dialog .p-dialog-footer {
//   margin: 1.5rem -1.5rem -1.5rem -1.5rem;
//   padding: 1rem 1.5rem;
//   box-shadow: 0 -3px 13px rgba(0, 0, 0, 0.075);
// }
.p-dialog .p-dialog-footer {
  bottom: 1rem;
  margin-left: 0;
}

.ui-form-footer {
  position: inherit;
  width: inherit;
  margin-top: 2.5rem;
}

button.p-button-icon-only.p-button-rounded.p-button-sm {
  height: 1.85rem;
  width: 1.85rem;
  padding: 0;
}

.p-steps .p-steps-item {
  .p-menuitem-link .p-steps-title {
    font-size: 0.9em;
  }
  &.p-highlight {
    .p-steps-number {
      border-color: var(--primary-color);
    }
    .p-steps-title {
      color: var(--primary-color);
    }
  }
}
.p-menu .menu-item-success .p-menuitem-link .p-menuitem-icon {
  color: #3cad3c;
}
.p-menu .menu-item-danger .p-menuitem-link .p-menuitem-icon {
  color: red;
}
.ui-state-complete .ui-steps-number {
  background-color: green !important;
}
.work-order-steps {
  margin: 2rem 0;
}

.disabled-form {
  opacity: 0.25;
}

.work-order-list {
  .p-card-body {
    padding: 0.5rem 1.5rem;
  }
  hr {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
}
.discount {
  color: #c63737;
}
.extra {
  color: #75ab55;
}
.work-order-summary {
  h3 {
    margin-bottom: 0.5rem;
  }
  .label,
  .total {
    padding: 0.4rem 0;
  }
  .label {
    color: $textSecondaryColor;
  }
  .total {
    font-weight: 600;
    &.discount {
      color: #c63737;
    }
    &.extra {
      color: #75ab55;
    }
  }
  .total-price {
    background: var(--primary-color);
    margin: -1.5rem;
    padding: 0.9rem 1.5rem;
    color: white;
    font-size: 1.15rem;
    border-radius: 0 0 8px 8px;
    .label,
    .total {
      color: white;

      @media print {
        color: black;
      }
    }
  }
}
.status-indicator {
  text-transform: uppercase;
  font-size: 0.8rem;
  font-weight: 700;

  i {
    display: inline-block;
    height: 8px;
    width: 8px;
    border-radius: 100px;
    margin-right: 0.15rem;
  }

  &.draft {
    i {
      background-color: var(--status-color-draft);
    }
    color: var(--status-color-draft);
  }
  &.scheduled {
    i {
      background-color: var(--status-color-scheduled);
    }
    color: var(--status-color-scheduled);
  }
  &.complete {
    i {
      background-color: var(--status-color-complete);
    }
    color: var(--status-color-complete);
  }
}

.work-order-preview {
  .p-card-body {
    padding: 0.5rem 1.5rem;
  }
  .amount {
  }
  .work-order-number {
    color: var(--color-light-grey);
  }
  .icon {
    color: var(--color-light-grey);
  }
}

.calendar-status {
  border-radius: 6px;
  width: 45px;
  text-align: center;
  border-width: 1px;
  border-style: solid;
  .month {
    color: white;
    text-transform: uppercase;
    font-size: 0.75rem;
    padding: 1px 0;
  }
  .day {
    font-weight: 600;
    font-size: 1rem;
    padding: 5px 0;
    line-height: 1;
  }
  &.draft {
    border-color: var(--status-color-draft);
    .month {
      background: var(--status-color-draft);
    }
  }
  &.scheduled {
    border-color: var(--status-color-scheduled);
    .month {
      background: var(--status-color-scheduled);
    }
  }
  &.complete {
    border-color: var(--status-color-complete);
    .month {
      background: var(--status-color-complete);
    }
  }
}

.p-button.elipsis {
  font-size: 2rem;
  color: var(--color-light-grey);
}
.map {
  margin-bottom: -1.5rem;
  border-radius: 0 8px 0 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.color-grey {
  color: var(--color-light-grey);
}

.customer-card {
  &:hover {
    cursor: pointer;
  }
  .p-card .p-card-body {
    padding: 1rem 1rem 0.5rem;
  }
  .p-grid {
    font-size: 0.8rem;
  }
}
.p-datatable .ui-column-data {
  display: inline-flex;
  word-break: break-all;
}
.p-datatable .p-datatable-thead > tr > th,
.p-datatable .p-datatable-tbody > tr > td {
  font-size: 0.9em;
}
.p-datatable .p-datatable-thead > tr.sort-options > th {
  padding: 0.5em 0.35em;
  background: none;
  .p-inputtext {
    height: 36px;
    line-height: 1;
    // font-size: 0.875em;
    font-size: 1em;
  }
}

.maintenance-table {
  .p-datatable {
    border: 1px solid #ebebeb;
  }
  .p-datatable .p-datatable-header {
    background: none;
    padding: 0 0 1.5rem 0;
  }
  .ui-column-title {
    display: none;
  }
}

/* Responsive */
@media screen and (max-width: 640px) {
  .maintenance-table .p-datatable {
    .p-datatable-thead > tr > th,
    .p-datatable-tfoot > tr > td {
      display: none !important;
    }

    colgroup {
      display: none;
    }

    .p-datatable-tbody > tr > td {
      text-align: left;
      display: block;
      border: 0 none !important;
      width: 100% !important;
      float: left;
      clear: left;
      border: 0 none;
      padding: 1rem 0 0;

      &:last-child {
        padding-bottom: 1rem;
      }

      .ui-column-title {
        padding: 0.4em 1em;
        width: 35%;
        display: inline-block;
        margin: -0.4em 1em -0.4em -0.4em;
        font-weight: bold;
      }
      .ui-column-data {
        display: inline-flex;
        word-break: break-all;
      }
    }
  }
}
.status-badge {
  border-radius: 6px;
  padding: 0.5em 1em;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.25px;
  white-space: nowrap;

  // width: 100%;
  display: inline-block;
  text-align: center;

  &.status-Called,
  &.status-Scheduled {
    background-color: var(--status-color-scheduled);
    color: #256029;
  }

  &.status-Draft,
  &.status-Not_Set {
    background-color: var(--status-color-draft);
    color: #6b7a8a;
  }

  &.status-Complete {
    background-color: #cce5f5;
    color: var(--status-color-complete);
  }

  &.status-Past_Due {
    background-color: #ffcdd2;
    color: #c63737;
  }

  &.status-Not_Called,
  &.status-Archived {
    background-color: #feedaf;
    color: #8a5340;
  }
}
