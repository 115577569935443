// Theme Specific Variables
$primaryColor: #1f88ca;
$primaryDarkColor: #0b669f;
$primaryDarkerColor: #074a74;
$primaryTextColor: #ffffff;

// Mandatory Designer Variables
@import "./variables/general";
@import "./variables/form";
@import "./variables/button";
@import "./variables/panel";
@import "./variables/_data";
@import "./variables/_overlay";
@import "./variables/_message";
@import "./variables/_menu";
@import "./variables/_media";
@import "./variables/_misc";

:root {
  --surface-a: #ffffff;
  --surface-b: #f8f9fa;
  --surface-c: #e9ecef;
  --surface-d: #dee2e6;
  --surface-e: #ffffff;
  --surface-f: #ffffff;
  --text-color: #495057;
  --text-color-secondary: #697580;
  --primary-color: #1f88ca;
  --primary-color-text: #ffffff;
  --font-family: "Open Sans", sans-serif;

  --color-light-grey: #a4abaf;
  --color-lighter-grey: #edf1f5;

  --status-color-draft: var(--color-lighter-grey);
  --status-color-scheduled: #c8e6c9;
  --status-color-complete: var(--primary-color);
}

$header-height: 70px;
$sub-header-height: 56px;

$left-nav-width: 70px;
