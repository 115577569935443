.color-white {
  color: white;
}
.full-width {
  width: 100%;
}
.no-overflow {
  overflow: inherit;
}
.flex-grow-1 {
  flex-grow: 1;
}
.print-only,
.print-only-inline {
  display: none;
}
.print-p-col-6 {
  width: 100%;
}
@media print {
  .p-print-none {
    display: none !important;
  }
  .print-only {
    display: block;
  }
  .print-only-inline {
    display: inline-block;
  }
  .print-p-col-6 {
    width: 50%;
  }
  .print-p-col-3 {
    width: 25%;
  }
  .print-p-col-9 {
    width: 75%;
  }

  .pagebreak {
    clear: both;
    page-break-after: always;
  }
}
