@media print {
  .site {
    margin: 0;
    max-width: none;
  }
  .header,
  .sub-header,
  .left-nav,
  .p-menu {
    display: none !important;
  }
  .p-card {
    box-shadow: none;
    border: 1px solid black;
    border-radius: 0;
  }
}
