.login-background {
  background: rgb(26, 39, 95);
  background: linear-gradient(
    135deg,
    rgba(26, 39, 95, 1) 0%,
    rgba(28, 77, 136, 1) 100%
  );
  height: 100vh;
  width: 100vw;
}

.login {
  height: 100vh;
  margin: 0;
  .login-container {
    max-width: 420px;
    width: 100%;
  }
  .p-field > label {
    font-size: 0.75rem;
    text-transform: uppercase;
    color: #ffffffa1;
    letter-spacing: 0.35px;
  }
  .p-inputtext {
    background: #ffffff26;
    border: none;
    color: white;
    font-weight: 400;
    &:hover,
    &:active,
    &:focus {
      background: white;
      color: black;
    }
  }
}
