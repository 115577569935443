@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap");
:root {
  --surface-a: #ffffff;
  --surface-b: #f8f9fa;
  --surface-c: #e9ecef;
  --surface-d: #dee2e6;
  --surface-e: #ffffff;
  --surface-f: #ffffff;
  --text-color: #495057;
  --text-color-secondary: #697580;
  --primary-color: #1f88ca;
  --primary-color-text: #ffffff;
  --font-family: "Open Sans", sans-serif;
  --color-light-grey: #a4abaf;
  --color-lighter-grey: #edf1f5;
  --status-color-draft: var(--color-lighter-grey);
  --status-color-scheduled: #c8e6c9;
  --status-color-complete: var(--primary-color);
}

* {
  box-sizing: border-box;
}

.p-component {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: normal;
}

.p-component-overlay {
  background-color: rgba(0, 0, 0, 0.4);
  transition-duration: 0.2s;
}

.p-disabled, .p-component:disabled {
  opacity: 0.8;
}

.p-error, .p-invalid {
  color: #e4677e;
}

.p-text-secondary {
  color: #808080;
}

.pi {
  font-size: 1rem;
}

.p-link {
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
  border-radius: 8px;
}
.p-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #bfd1f6;
}

.p-autocomplete .p-autocomplete-loader {
  right: 1rem;
}
.p-autocomplete.p-autocomplete-dd .p-autocomplete-loader {
  right: 3.357rem;
}
.p-autocomplete.p-autocomplete-multiple .p-autocomplete-multiple-container {
  padding: 0.375rem 1rem;
}
.p-autocomplete.p-autocomplete-multiple .p-autocomplete-multiple-container:not(.p-disabled):hover {
  border-color: #1f88ca;
}
.p-autocomplete.p-autocomplete-multiple .p-autocomplete-multiple-container:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #bfd1f6;
  border-color: #1f88ca;
}
.p-autocomplete.p-autocomplete-multiple .p-autocomplete-multiple-container .p-autocomplete-input-token {
  padding: 0.375rem 0;
}
.p-autocomplete.p-autocomplete-multiple .p-autocomplete-multiple-container .p-autocomplete-input-token input {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  color: #495057;
  padding: 0;
  margin: 0;
}
.p-autocomplete.p-autocomplete-multiple .p-autocomplete-multiple-container .p-autocomplete-token {
  padding: 0.375rem 1rem;
  margin-right: 0.5rem;
  background: #1f88ca;
  color: #ffffff;
  border-radius: 8px;
}
.p-autocomplete.p-autocomplete-multiple .p-autocomplete-multiple-container .p-autocomplete-token .p-autocomplete-token-icon {
  margin-left: 0.5rem;
}
.p-autocomplete.p-error > .p-inputtext, .p-autocomplete.p-invalid > .p-inputtext {
  border-color: #f6f6f7 #f6f6f7 #f6f6f7 #e4677e;
}

p-autocomplete.ng-dirty.ng-invalid > .p-autocomplete > .p-inputtext {
  border-color: #f6f6f7 #f6f6f7 #f6f6f7 #e4677e;
}

.p-autocomplete-panel {
  background: #ffffff;
  color: #495057;
  border: 0 none;
  border-radius: 8px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
}
.p-autocomplete-panel .p-autocomplete-items {
  padding: 0.5rem 0;
}
.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item {
  margin: 0;
  padding: 0.5rem 1rem;
  border: 0 none;
  color: #495057;
  background: transparent;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 0;
}
.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item:hover {
  color: #495057;
  background: #e9ecef;
}
.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item.p-highlight {
  color: #ffffff;
  background: #1f88ca;
}

.p-calendar.p-error > .p-inputtext, .p-calendar.p-invalid > .p-inputtext {
  border-color: #f6f6f7 #f6f6f7 #f6f6f7 #e4677e;
}

p-calendar.ng-dirty.ng-invalid > .p-calendar > .p-inputtext {
  border-color: #f6f6f7 #f6f6f7 #f6f6f7 #e4677e;
}

.p-datepicker {
  padding: 0.5rem;
  background: #ffffff;
  color: #495057;
  border: 1px solid #dee2e6;
  border-radius: 8px;
}
.p-datepicker:not(.p-datepicker-inline) {
  background: #ffffff;
  border: 0 none;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
}
.p-datepicker:not(.p-datepicker-inline) .p-datepicker-header {
  background: #ffffff;
}
.p-datepicker .p-datepicker-header {
  padding: 0.5rem;
  color: #495057;
  background: #ffffff;
  font-weight: 600;
  margin: 0;
  border-bottom: 1px solid #dee2e6;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}
.p-datepicker .p-datepicker-header .p-datepicker-prev,
.p-datepicker .p-datepicker-header .p-datepicker-next {
  width: 2rem;
  height: 2rem;
  color: #808080;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.p-datepicker .p-datepicker-header .p-datepicker-prev:enabled:hover,
.p-datepicker .p-datepicker-header .p-datepicker-next:enabled:hover {
  color: #495057;
  border-color: transparent;
  background: #e9ecef;
}
.p-datepicker .p-datepicker-header .p-datepicker-prev:focus,
.p-datepicker .p-datepicker-header .p-datepicker-next:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #bfd1f6;
}
.p-datepicker .p-datepicker-header .p-datepicker-title {
  line-height: 1;
}
.p-datepicker .p-datepicker-header .p-datepicker-title select {
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.p-datepicker .p-datepicker-header .p-datepicker-title select:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #bfd1f6;
  border-color: #1f88ca;
}
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
  margin-right: 0.5rem;
}
.p-datepicker table {
  font-size: 14px;
  margin: 0.5rem 0;
}
.p-datepicker table th {
  padding: 0.5rem;
}
.p-datepicker table th > span {
  width: 2.5rem;
  height: 2.5rem;
}
.p-datepicker table td {
  padding: 0.5rem;
}
.p-datepicker table td > span {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border: 1px solid transparent;
}
.p-datepicker table td > span.p-highlight {
  color: #ffffff;
  background: #1f88ca;
}
.p-datepicker table td > span:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #bfd1f6;
}
.p-datepicker table td.p-datepicker-today > span {
  background: #ced4da;
  color: #495057;
  border-color: transparent;
}
.p-datepicker table td.p-datepicker-today > span.p-highlight {
  color: #ffffff;
  background: #1f88ca;
}
.p-datepicker .p-datepicker-buttonbar {
  padding: 1rem 0;
  border-top: 1px solid #dee2e6;
}
.p-datepicker .p-datepicker-buttonbar .p-button {
  width: auto;
}
.p-datepicker .p-timepicker {
  border-top: 1px solid #dee2e6;
  padding: 0.5rem;
}
.p-datepicker .p-timepicker button {
  width: 2rem;
  height: 2rem;
  color: #808080;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.p-datepicker .p-timepicker button:enabled:hover {
  color: #495057;
  border-color: transparent;
  background: #e9ecef;
}
.p-datepicker .p-timepicker button:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #bfd1f6;
}
.p-datepicker .p-timepicker button:last-child {
  margin-top: 0.2em;
}
.p-datepicker .p-timepicker span {
  font-size: 1.286rem;
}
.p-datepicker .p-timepicker > div {
  padding: 0 0.429rem;
}
.p-datepicker.p-datepicker-timeonly .p-timepicker {
  border-top: 0 none;
}
.p-datepicker .p-monthpicker {
  margin: 0.5rem 0;
}
.p-datepicker .p-monthpicker .p-monthpicker-month {
  padding: 0.5rem;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 8px;
}
.p-datepicker .p-monthpicker .p-monthpicker-month.p-highlight {
  color: #ffffff;
  background: #1f88ca;
}
.p-datepicker.p-datepicker-multiple-month .p-datepicker-group {
  border-right: 1px solid #dee2e6;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  padding-top: 0;
  padding-bottom: 0;
}
.p-datepicker.p-datepicker-multiple-month .p-datepicker-group:first-child {
  padding-left: 0;
}
.p-datepicker.p-datepicker-multiple-month .p-datepicker-group:last-child {
  padding-right: 0;
  border-right: 0 none;
}
.p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):hover {
  background: #e9ecef;
}
.p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #bfd1f6;
}
.p-datepicker:not(.p-disabled) .p-monthpicker .p-monthpicker-month:not(.p-highlight):not(.p-disabled):hover {
  background: #e9ecef;
}
.p-datepicker:not(.p-disabled) .p-monthpicker .p-monthpicker-month:not(.p-highlight):not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #bfd1f6;
}

@media screen and (max-width: 769px) {
  .p-datepicker table th, .p-datepicker table td {
    padding: 0;
  }
}
.p-checkbox {
  width: 20px;
  height: 20px;
}
.p-checkbox .p-checkbox-box {
  border: 2px solid #ced4da;
  background: #ffffff;
  width: 20px;
  height: 20px;
  color: #495057;
  border-radius: 8px;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.p-checkbox .p-checkbox-box .p-checkbox-icon {
  transition-duration: 0.2s;
  color: #ffffff;
  font-size: 14px;
}
.p-checkbox .p-checkbox-box:not(.p-disabled):hover {
  border-color: #1f88ca;
}
.p-checkbox .p-checkbox-box:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #bfd1f6;
  border-color: #1f88ca;
}
.p-checkbox .p-checkbox-box.p-highlight {
  border-color: #1f88ca;
  background: #1f88ca;
}
.p-checkbox .p-checkbox-box.p-highlight:not(.p-disabled):hover {
  border-color: #074a74;
  background: #074a74;
  color: #ffffff;
}
.p-checkbox.p-error > .p-checkbox-box, .p-checkbox.p-invalid > .p-checkbox-box {
  border-color: #f6f6f7 #f6f6f7 #f6f6f7 #e4677e;
}

p-checkbox.ng-dirty.ng-invalid > .p-checkbox > .p-checkbox-box {
  border-color: #f6f6f7 #f6f6f7 #f6f6f7 #e4677e;
}

.p-input-filled .p-checkbox .p-checkbox-box {
  background-color: #f5f7f9;
}
.p-input-filled .p-checkbox .p-checkbox-box:not(.p-disabled):hover {
  background-color: #f8f9fa;
}
.p-input-filled .p-checkbox .p-checkbox-box.p-highlight {
  background: #1f88ca;
}
.p-input-filled .p-checkbox .p-checkbox-box.p-highlight:not(.p-disabled):hover {
  background: #074a74;
}

.p-checkbox-label {
  margin-left: 0.5rem;
}

.p-highlight .p-checkbox .p-checkbox-box {
  border-color: #ffffff;
}

.p-chips .p-chips-multiple-container {
  padding: 0.375rem 1rem;
}
.p-chips .p-chips-multiple-container:not(.p-disabled):hover {
  border-color: #1f88ca;
}
.p-chips .p-chips-multiple-container:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #bfd1f6;
  border-color: #1f88ca;
}
.p-chips .p-chips-multiple-container .p-chips-token {
  padding: 0.375rem 1rem;
  margin-right: 0.5rem;
  background: #1f88ca;
  color: #ffffff;
  border-radius: 8px;
}
.p-chips .p-chips-multiple-container .p-chips-token .p-chips-token-icon {
  margin-left: 0.5rem;
}
.p-chips .p-chips-multiple-container .p-chips-input-token {
  padding: 0.375rem 0;
}
.p-chips .p-chips-multiple-container .p-chips-input-token input {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  color: #495057;
  padding: 0;
  margin: 0;
}
.p-chips.p-error > .p-inputtext, .p-chips.p-invalid > .p-inputtext {
  border-color: #f6f6f7 #f6f6f7 #f6f6f7 #e4677e;
}

p-chips.ng-dirty.ng-invalid > .p-chips > .p-inputtext {
  border-color: #f6f6f7 #f6f6f7 #f6f6f7 #e4677e;
}

.p-colorpicker-preview {
  width: 2rem;
  height: 2rem;
}

.p-colorpicker-panel {
  background: #323232;
  border-color: #191919;
}
.p-colorpicker-panel .p-colorpicker-color-handle,
.p-colorpicker-panel .p-colorpicker-hue-handle {
  border-color: #ffffff;
}

.p-colorpicker-overlay-panel {
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
}

.p-dropdown {
  background: #ffffff;
  border: 1px solid #dee2e6;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 8px;
}
.p-dropdown:not(.p-disabled):hover {
  border-color: #1f88ca;
}
.p-dropdown:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #bfd1f6;
  border-color: #1f88ca;
}
.p-dropdown.p-dropdown-clearable .p-dropdown-label {
  padding-right: 2rem;
}
.p-dropdown .p-dropdown-label {
  background: transparent;
  border: 0 none;
}
.p-dropdown .p-dropdown-label.p-placeholder {
  color: #6c757d;
}
.p-dropdown .p-dropdown-label:enabled:focus {
  outline: 0 none;
  box-shadow: none;
}
.p-dropdown .p-dropdown-trigger {
  background: transparent;
  color: #495057;
  width: 2.357rem;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}
.p-dropdown .p-dropdown-clear-icon {
  color: #495057;
  right: 2.357rem;
}

.p-dropdown-panel {
  background: #ffffff;
  color: #495057;
  border: 0 none;
  border-radius: 8px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
}
.p-dropdown-panel .p-dropdown-header {
  padding: 0.5rem 1rem;
  border-bottom: 0 none;
  color: #495057;
  background: #f8f9fa;
  margin: 0;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}
.p-dropdown-panel .p-dropdown-header .p-dropdown-filter {
  padding-right: 2rem;
}
.p-dropdown-panel .p-dropdown-header .p-dropdown-filter-icon {
  right: 1rem;
  color: #495057;
}
.p-dropdown-panel .p-dropdown-items {
  padding: 0.5rem 0;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  margin: 0;
  padding: 0.5rem 1rem;
  border: 0 none;
  color: #495057;
  background: transparent;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 0;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  color: #ffffff;
  background: #1f88ca;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
  color: #495057;
  background: #e9ecef;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item-group {
  margin: 0;
  padding: 0.75rem 1rem;
  color: #495057;
  background: #ffffff;
  font-weight: 600;
}
.p-dropdown-panel.p-error, .p-dropdown-panel.p-invalid {
  border-color: #f6f6f7 #f6f6f7 #f6f6f7 #e4677e;
}

p-dropdown.ng-dirty.ng-invalid > .p-dropdown {
  border-color: #f6f6f7 #f6f6f7 #f6f6f7 #e4677e;
}

.p-input-filled .p-dropdown {
  background: #f5f7f9;
}
.p-input-filled .p-dropdown:not(.p-disabled):hover {
  background-color: #f8f9fa;
}
.p-input-filled .p-dropdown:not(.p-disabled).p-focus {
  background-color: #f8f9fa;
}

.p-editor-container .p-editor-toolbar {
  background: #f8f9fa;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}
.p-editor-container .p-editor-toolbar.ql-snow {
  border: 1px solid #dee2e6;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-stroke {
  stroke: #6c757d;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-fill {
  fill: #6c757d;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label {
  border: 0 none;
  color: #6c757d;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label:hover {
  color: #495057;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label:hover .ql-stroke {
  stroke: #495057;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label:hover .ql-fill {
  fill: #495057;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label {
  color: #495057;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-stroke {
  stroke: #495057;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-fill {
  fill: #495057;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  background: #ffffff;
  border: 0 none;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options .ql-picker-item {
  color: #495057;
  padding: 0.5rem 1rem;
  border-radius: 8px;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options .ql-picker-item:hover {
  color: #495057;
  background: #e9ecef;
}
.p-editor-container .p-editor-content {
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}
.p-editor-container .p-editor-content.ql-snow {
  border: 1px solid #dee2e6;
}
.p-editor-container .p-editor-content .ql-editor {
  background: #ffffff;
  color: #495057;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}
.p-editor-container .ql-snow.ql-toolbar button:hover,
.p-editor-container .ql-snow.ql-toolbar button:focus {
  color: #495057;
}
.p-editor-container .ql-snow.ql-toolbar button:hover .ql-stroke,
.p-editor-container .ql-snow.ql-toolbar button:focus .ql-stroke {
  stroke: #495057;
}
.p-editor-container .ql-snow.ql-toolbar button:hover .ql-fill,
.p-editor-container .ql-snow.ql-toolbar button:focus .ql-fill {
  fill: #495057;
}
.p-editor-container .ql-snow.ql-toolbar button.ql-active,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected {
  color: #1f88ca;
}
.p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-stroke,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke {
  stroke: #1f88ca;
}
.p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-fill,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill {
  fill: #1f88ca;
}
.p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-picker-label,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-picker-label,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-picker-label {
  color: #1f88ca;
}

.p-inputgroup-addon {
  background: #e9ecef;
  color: #808080;
  border-top: 1px solid #dee2e6;
  border-left: 1px solid #dee2e6;
  border-bottom: 1px solid #dee2e6;
  padding: 0.75rem 1rem;
  min-width: 2.357rem;
}
.p-inputgroup-addon:last-child {
  border-right: 1px solid #dee2e6;
}

.p-inputgroup > .p-component,
.p-inputgroup > .p-float-label > .p-component {
  border-radius: 0;
  margin: 0;
}
.p-inputgroup > .p-component + .p-inputgroup-addon,
.p-inputgroup > .p-float-label > .p-component + .p-inputgroup-addon {
  border-left: 0 none;
}
.p-inputgroup > .p-component:focus,
.p-inputgroup > .p-float-label > .p-component:focus {
  z-index: 1;
}
.p-inputgroup > .p-component:focus ~ label,
.p-inputgroup > .p-float-label > .p-component:focus ~ label {
  z-index: 1;
}

.p-inputgroup-addon:first-child,
.p-inputgroup button:first-child,
.p-inputgroup input:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.p-inputgroup .p-float-label:first-child input {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.p-inputgroup-addon:last-child,
.p-inputgroup button:last-child,
.p-inputgroup input:last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.p-inputgroup .p-float-label:last-child input {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.p-fluid .p-inputgroup .p-button {
  width: auto;
}
.p-fluid .p-inputgroup .p-button.p-button-icon-only {
  width: 2.357rem;
}

p-inputmask.ng-dirty.ng-invalid > .p-inputtext {
  border-color: #f6f6f7 #f6f6f7 #f6f6f7 #e4677e;
}

p-inputnumber.ng-dirty.ng-invalid > .p-inputtext {
  border-color: #f6f6f7 #f6f6f7 #f6f6f7 #e4677e;
}

.p-inputswitch {
  width: 3rem;
  height: 1.75rem;
}
.p-inputswitch .p-inputswitch-slider {
  background: #ced4da;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 30px;
}
.p-inputswitch .p-inputswitch-slider:before {
  background: #ffffff;
  width: 1.25rem;
  height: 1.25rem;
  left: 0.25rem;
  margin-top: -0.625rem;
  border-radius: 50%;
  transition-duration: 0.2s;
}
.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
  transform: translateX(1.25rem);
}
.p-inputswitch.p-focus .p-inputswitch-slider {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #bfd1f6;
}
.p-inputswitch:not(.p-disabled):hover .p-inputswitch-slider {
  background: #c3cad2;
}
.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background: #1f88ca;
}
.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
  background: #ffffff;
}
.p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider {
  background: #0b669f;
}
.p-inputswitch.p-error, .p-inputswitch.p-invalid {
  border-color: #f6f6f7 #f6f6f7 #f6f6f7 #e4677e;
}

p-inputswitch.ng-dirty.ng-invalid > .p-inputswitch {
  border-color: #f6f6f7 #f6f6f7 #f6f6f7 #e4677e;
}

.p-inputtext {
  font-family: "Open Sans", sans-serif;
  font-size: 1em;
  color: #495057;
  background: #ffffff;
  padding: 0.75rem 1rem;
  border: 1px solid #dee2e6;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  appearance: none;
  border-radius: 8px;
}
.p-inputtext:enabled:hover {
  border-color: #1f88ca;
}
.p-inputtext:enabled:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #bfd1f6;
  border-color: #1f88ca;
}
.p-inputtext.p-error, .p-inputtext.p-invalid, .p-inputtext.ng-dirty.ng-invalid {
  border-color: #f6f6f7 #f6f6f7 #f6f6f7 #e4677e;
}
.p-inputtext.p-inputtext-sm {
  font-size: 0.875em;
  padding: 0.65625rem 0.875rem;
}
.p-inputtext.p-inputtext-lg {
  font-size: 1.25em;
  padding: 0.9375rem 1.25rem;
}

.p-float-label > label {
  left: 1rem;
  color: #6c757d;
  transition-duration: 0.2s;
}

.p-input-icon-left > i:first-of-type {
  left: 1rem;
  color: #495057;
}

.p-input-icon-left > .p-inputtext {
  padding-left: 3rem;
}

.p-input-icon-right > i:last-of-type {
  right: 1rem;
  color: #495057;
}

.p-input-icon-right > .p-inputtext {
  padding-right: 3rem;
}

::-webkit-input-placeholder {
  color: #6c757d;
}

:-moz-placeholder {
  color: #6c757d;
}

::-moz-placeholder {
  color: #6c757d;
}

:-ms-input-placeholder {
  color: #6c757d;
}

.p-input-filled .p-inputtext {
  background-color: #f5f7f9;
}
.p-input-filled .p-inputtext:enabled:hover {
  background-color: #f8f9fa;
}
.p-input-filled .p-inputtext:enabled:focus {
  background-color: #f8f9fa;
}

.p-inputtext-sm .p-inputtext {
  font-size: 0.875em;
  padding: 0.65625rem 0.875rem;
}

.p-inputtext-lg .p-inputtext {
  font-size: 1.25em;
  padding: 0.9375rem 1.25rem;
}

.p-listbox {
  background: #ffffff;
  color: #495057;
  border: 1px solid #dee2e6;
  border-radius: 8px;
}
.p-listbox .p-listbox-header {
  padding: 0.5rem 1rem;
  border-bottom: 0 none;
  color: #495057;
  background: #f8f9fa;
  margin: 0;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}
.p-listbox .p-listbox-header .p-listbox-filter {
  padding-right: 2rem;
}
.p-listbox .p-listbox-header .p-listbox-filter-icon {
  right: 1rem;
  color: #495057;
}
.p-listbox .p-listbox-header .p-checkbox {
  margin-right: 0.5rem;
}
.p-listbox .p-listbox-list {
  padding: 0.5rem 0;
}
.p-listbox .p-listbox-list .p-listbox-item {
  margin: 0;
  padding: 0.5rem 1rem;
  border: 0 none;
  color: #495057;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 0;
}
.p-listbox .p-listbox-list .p-listbox-item.p-highlight {
  color: #ffffff;
  background: #1f88ca;
}
.p-listbox .p-listbox-list .p-listbox-item:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem #bfd1f6;
}
.p-listbox .p-listbox-list .p-listbox-item .p-checkbox {
  margin-right: 0.5rem;
}
.p-listbox:not(.p-disabled) .p-listbox-item:not(.p-highlight):not(.p-disabled):hover {
  color: #495057;
  background: #e9ecef;
}
.p-listbox.p-error, .p-listbox.p-invalid {
  border-color: #f6f6f7 #f6f6f7 #f6f6f7 #e4677e;
}

p-listbox.ng-dirty.ng-invalid > .p-listbox {
  border-color: #f6f6f7 #f6f6f7 #f6f6f7 #e4677e;
}

.p-multiselect {
  background: #ffffff;
  border: 1px solid #dee2e6;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 8px;
}
.p-multiselect:not(.p-disabled):hover {
  border-color: #1f88ca;
}
.p-multiselect:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #bfd1f6;
  border-color: #1f88ca;
}
.p-multiselect .p-multiselect-label {
  padding: 0.75rem 1rem;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.p-multiselect .p-multiselect-label.p-placeholder {
  color: #6c757d;
}
.p-multiselect .p-multiselect-trigger {
  background: transparent;
  color: #495057;
  width: 2.357rem;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}
.p-multiselect.p-error, .p-multiselect.p-invalid {
  border-color: #f6f6f7 #f6f6f7 #f6f6f7 #e4677e;
}

.p-multiselect-panel {
  background: #ffffff;
  color: #495057;
  border: 0 none;
  border-radius: 8px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
}
.p-multiselect-panel .p-multiselect-header {
  padding: 0.5rem 1rem;
  border-bottom: 0 none;
  color: #495057;
  background: #f8f9fa;
  margin: 0;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}
.p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-inputtext {
  padding-right: 2rem;
}
.p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-multiselect-filter-icon {
  right: 1rem;
  color: #495057;
}
.p-multiselect-panel .p-multiselect-header .p-checkbox {
  margin-right: 0.5rem;
}
.p-multiselect-panel .p-multiselect-header .p-multiselect-close {
  margin-left: 0.5rem;
  width: 2rem;
  height: 2rem;
  color: #808080;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.p-multiselect-panel .p-multiselect-header .p-multiselect-close:enabled:hover {
  color: #495057;
  border-color: transparent;
  background: #e9ecef;
}
.p-multiselect-panel .p-multiselect-header .p-multiselect-close:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #bfd1f6;
}
.p-multiselect-panel .p-multiselect-items {
  padding: 0.5rem 0;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item {
  margin: 0;
  padding: 0.5rem 1rem;
  border: 0 none;
  color: #495057;
  background: transparent;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 0;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
  color: #ffffff;
  background: #1f88ca;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item:not(.p-highlight):not(.p-disabled):hover {
  color: #495057;
  background: #e9ecef;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem #bfd1f6;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item .p-checkbox {
  margin-right: 0.5rem;
}

p-multiselect.ng-dirty.ng-invalid > .p-multiselect {
  border-color: #f6f6f7 #f6f6f7 #f6f6f7 #e4677e;
}

.p-input-filled .p-multiselect {
  background: #f5f7f9;
}
.p-input-filled .p-multiselect:not(.p-disabled):hover {
  background-color: #f8f9fa;
}
.p-input-filled .p-multiselect:not(.p-disabled).p-focus {
  background-color: #f8f9fa;
}

.p-password-panel {
  padding: 1rem;
  background: #ffffff;
  color: #495057;
  border: 0 none;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}
.p-password-panel .p-password-meter {
  margin-bottom: 0.5rem;
}

.p-radiobutton {
  width: 20px;
  height: 20px;
}
.p-radiobutton .p-radiobutton-box {
  border: 2px solid #ced4da;
  background: #ffffff;
  width: 20px;
  height: 20px;
  color: #495057;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.p-radiobutton .p-radiobutton-box:not(.p-disabled):not(.p-highlight):hover {
  border-color: #1f88ca;
}
.p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #bfd1f6;
  border-color: #1f88ca;
}
.p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
  width: 12px;
  height: 12px;
  transition-duration: 0.2s;
  background-color: #ffffff;
}
.p-radiobutton .p-radiobutton-box.p-highlight {
  border-color: #1f88ca;
  background: #1f88ca;
}
.p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
  border-color: #074a74;
  background: #074a74;
  color: #ffffff;
}
.p-radiobutton.p-error > .p-radiobutton-box, .p-radiobutton.p-invalid > .p-radiobutton-box {
  border-color: #f6f6f7 #f6f6f7 #f6f6f7 #e4677e;
}

p-radiobutton.ng-dirty.ng-invalid > .p-radiobutton > .p-radiobutton-box {
  border-color: #f6f6f7 #f6f6f7 #f6f6f7 #e4677e;
}

.p-input-filled .p-radiobutton .p-radiobutton-box {
  background-color: #f5f7f9;
}
.p-input-filled .p-radiobutton .p-radiobutton-box:not(.p-disabled):hover {
  background-color: #f8f9fa;
}
.p-input-filled .p-radiobutton .p-radiobutton-box.p-highlight {
  background: #1f88ca;
}
.p-input-filled .p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
  background: #074a74;
}

.p-radiobutton-label {
  margin-left: 0.5rem;
}

.p-highlight .p-radiobutton .p-radiobutton-box {
  border-color: #ffffff;
}

.p-rating .p-rating-icon {
  color: #495057;
  margin-left: 0.5rem;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  font-size: 1.143rem;
}
.p-rating .p-rating-icon.p-rating-cancel {
  color: #e74c3c;
}
.p-rating .p-rating-icon:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #bfd1f6;
}
.p-rating .p-rating-icon:first-child {
  margin-left: 0;
}
.p-rating .p-rating-icon.pi-star {
  color: #1f88ca;
}
.p-rating:not(.p-disabled):not(.p-readonly) .p-rating-icon:hover {
  color: #1f88ca;
}
.p-rating:not(.p-disabled):not(.p-readonly) .p-rating-icon.p-rating-cancel:hover {
  color: #c0392b;
}

.p-highlight .p-rating .p-rating-icon {
  color: #ffffff;
}

.p-selectbutton .p-button {
  background: #ffffff;
  border: 1px solid #ced4da;
  color: #495057;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.p-selectbutton .p-button .p-button-icon-left,
.p-selectbutton .p-button .p-button-icon-right {
  color: #808080;
}
.p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover {
  background: #e9ecef;
  border-color: #ced4da;
  color: #495057;
}
.p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-left,
.p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-right {
  color: #808080;
}
.p-selectbutton .p-button.p-highlight {
  background: #1f88ca;
  border-color: #1f88ca;
  color: #ffffff;
}
.p-selectbutton .p-button.p-highlight .p-button-icon-left,
.p-selectbutton .p-button.p-highlight .p-button-icon-right {
  color: #ffffff;
}
.p-selectbutton .p-button.p-highlight:hover {
  background: #0b669f;
  border-color: #0b669f;
  color: #ffffff;
}
.p-selectbutton .p-button.p-highlight:hover .p-button-icon-left,
.p-selectbutton .p-button.p-highlight:hover .p-button-icon-right {
  color: #ffffff;
}
.p-selectbutton.p-error > .p-button, .p-selectbutton.p-invalid > .p-button {
  border-color: #f6f6f7 #f6f6f7 #f6f6f7 #e4677e;
}

p-selectbutton.ng-dirty.ng-invalid > .p-selectbutton > .p-button {
  border-color: #f6f6f7 #f6f6f7 #f6f6f7 #e4677e;
}

.p-slider {
  background: #dee2e6;
  border: 0 none;
  border-radius: 8px;
}
.p-slider.p-slider-horizontal {
  height: 0.286rem;
}
.p-slider.p-slider-horizontal .p-slider-handle {
  margin-top: -0.5715rem;
  margin-left: -0.5715rem;
}
.p-slider.p-slider-vertical {
  width: 0.286rem;
}
.p-slider.p-slider-vertical .p-slider-handle {
  margin-left: -0.5715rem;
  margin-bottom: -0.5715rem;
}
.p-slider .p-slider-handle {
  height: 1.143rem;
  width: 1.143rem;
  background: #ffffff;
  border: 2px solid #1f88ca;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.p-slider .p-slider-handle:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #bfd1f6;
}
.p-slider .p-slider-range {
  background: #1f88ca;
}
.p-slider:not(.p-disabled) .p-slider-handle:hover {
  background: #1f88ca;
  border-color: #1f88ca;
}
.p-slider.p-slider-animate.p-slider-horizontal .p-slider-handle {
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, left 0.2s;
}
.p-slider.p-slider-animate.p-slider-horizontal .p-slider-range {
  transition: width 0.2s;
}
.p-slider.p-slider-animate.p-slider-vertical .p-slider-handle {
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, bottom 0.2s;
}
.p-slider.p-slider-animate.p-slider-vertical .p-slider-range {
  transition: height 0.2s;
}

.p-togglebutton.p-button {
  background: #ffffff;
  border: 1px solid #ced4da;
  color: #495057;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.p-togglebutton.p-button .p-button-icon-left,
.p-togglebutton.p-button .p-button-icon-right {
  color: #808080;
}
.p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover {
  background: #e9ecef;
  border-color: #ced4da;
  color: #495057;
}
.p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-left,
.p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-right {
  color: #808080;
}
.p-togglebutton.p-button.p-highlight {
  background: #1f88ca;
  border-color: #1f88ca;
  color: #ffffff;
}
.p-togglebutton.p-button.p-highlight .p-button-icon-left,
.p-togglebutton.p-button.p-highlight .p-button-icon-right {
  color: #ffffff;
}
.p-togglebutton.p-button.p-highlight:hover {
  background: #0b669f;
  border-color: #0b669f;
  color: #ffffff;
}
.p-togglebutton.p-button.p-highlight:hover .p-button-icon-left,
.p-togglebutton.p-button.p-highlight:hover .p-button-icon-right {
  color: #ffffff;
}
.p-togglebutton.p-button.p-error, .p-togglebutton.p-button.p-invalid {
  border-color: #f6f6f7 #f6f6f7 #f6f6f7 #e4677e;
}

p-togglebutton.ng-dirty.ng-invalid > .p-togglebutton.p-button {
  border-color: #f6f6f7 #f6f6f7 #f6f6f7 #e4677e;
}

.p-button {
  color: #ffffff;
  background: #1f88ca;
  border: 1px solid #1f88ca;
  padding: 0.8445rem 2rem;
  font-size: 14px;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 8px;
}
.p-button:enabled:hover {
  background: #0b669f;
  color: #ffffff;
  border-color: #0b669f;
}
.p-button:enabled:active {
  background: #074a74;
  color: #ffffff;
  border-color: #074a74;
}
.p-button.p-button-outlined {
  background-color: transparent;
  color: #1f88ca;
  border: 1px solid;
}
.p-button.p-button-outlined:enabled:hover {
  background: rgba(31, 136, 202, 0);
  color: #1f88ca;
  border: 1px solid;
}
.p-button.p-button-outlined:enabled:active {
  background: rgba(31, 136, 202, 0.16);
  color: #1f88ca;
  border: 1px solid;
}
.p-button.p-button-text {
  background-color: transparent;
  color: #1f88ca;
  border-color: transparent;
}
.p-button.p-button-text:enabled:hover {
  background: rgba(31, 136, 202, 0);
  color: #1f88ca;
  border-color: transparent;
}
.p-button.p-button-text:enabled:active {
  background: rgba(31, 136, 202, 0.16);
  color: #1f88ca;
  border-color: transparent;
}
.p-button.p-button-text.p-button-plain {
  color: #6c757d;
}
.p-button.p-button-text.p-button-plain:enabled:hover {
  background: #e9ecef;
  color: #6c757d;
}
.p-button.p-button-text.p-button-plain:enabled:active {
  background: #dee2e6;
  color: #6c757d;
}
.p-button:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #bfd1f6;
}
.p-button .p-button-icon-left {
  margin-right: 0.5rem;
}
.p-button .p-button-icon-right {
  margin-left: 0.5rem;
}
.p-button .p-button-icon-bottom {
  margin-top: 0.5rem;
}
.p-button .p-button-icon-top {
  margin-bottom: 0.5rem;
}
.p-button .p-badge {
  margin-left: 0.5rem;
  min-width: 14px;
  height: 14px;
  line-height: 14px;
  color: #1f88ca;
  background-color: #ffffff;
}
.p-button.p-button-raised {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.p-button.p-button-rounded {
  border-radius: 2rem;
}
.p-button.p-button-icon-only {
  width: 2.357rem;
  padding: 0.5rem 0;
}
.p-button.p-button-icon-only .p-button-icon-left,
.p-button.p-button-icon-only .p-button-icon-right {
  margin: 0;
}
.p-button.p-button-icon-only.p-button-rounded {
  border-radius: 50%;
  height: 2.357rem;
}
.p-button.p-button-sm {
  font-size: 12.25px;
  padding: 0.7389375rem 1.75rem;
}
.p-button.p-button-sm .p-button-icon {
  font-size: 0.875rem;
}
.p-button.p-button-lg {
  font-size: 17.5px;
  padding: 1.055625rem 2.5rem;
}
.p-button.p-button-lg .p-button-icon {
  font-size: 1.25rem;
}

.p-fluid .p-button {
  width: 100%;
}
.p-fluid .p-button-icon-only {
  width: 2.357rem;
}
.p-fluid .p-buttonset {
  display: flex;
}
.p-fluid .p-buttonset .p-button {
  flex: 1;
}

.p-button.p-button-secondary, .p-buttonset.p-button-secondary > .p-button, .p-splitbutton.p-button-secondary > .p-button {
  color: #ffffff;
  background: #bbb;
  border: 1px solid #607d8b;
}
.p-button.p-button-secondary:enabled:hover, .p-buttonset.p-button-secondary > .p-button:enabled:hover, .p-splitbutton.p-button-secondary > .p-button:enabled:hover {
  background: #546e7a;
  color: #ffffff;
  border-color: #546e7a;
}
.p-button.p-button-secondary:enabled:focus, .p-buttonset.p-button-secondary > .p-button:enabled:focus, .p-splitbutton.p-button-secondary > .p-button:enabled:focus {
  box-shadow: 0 0 0 0.2rem #b0bec5;
}
.p-button.p-button-secondary:enabled:active, .p-buttonset.p-button-secondary > .p-button:enabled:active, .p-splitbutton.p-button-secondary > .p-button:enabled:active {
  background: #455a64;
  color: #ffffff;
  border-color: #455a64;
}
.p-button.p-button-secondary.p-button-outlined, .p-buttonset.p-button-secondary > .p-button.p-button-outlined, .p-splitbutton.p-button-secondary > .p-button.p-button-outlined {
  background-color: transparent;
  color: #bbb;
  border: 1px solid;
}
.p-button.p-button-secondary.p-button-outlined:enabled:hover, .p-buttonset.p-button-secondary > .p-button.p-button-outlined:enabled:hover, .p-splitbutton.p-button-secondary > .p-button.p-button-outlined:enabled:hover {
  background: rgba(187, 187, 187, 0);
  color: #bbb;
  border: 1px solid;
}
.p-button.p-button-secondary.p-button-outlined:enabled:active, .p-buttonset.p-button-secondary > .p-button.p-button-outlined:enabled:active, .p-splitbutton.p-button-secondary > .p-button.p-button-outlined:enabled:active {
  background: rgba(187, 187, 187, 0.16);
  color: #bbb;
  border: 1px solid;
}
.p-button.p-button-secondary.p-button-text, .p-buttonset.p-button-secondary > .p-button.p-button-text, .p-splitbutton.p-button-secondary > .p-button.p-button-text {
  background-color: transparent;
  color: #bbb;
  border-color: transparent;
}
.p-button.p-button-secondary.p-button-text:enabled:hover, .p-buttonset.p-button-secondary > .p-button.p-button-text:enabled:hover, .p-splitbutton.p-button-secondary > .p-button.p-button-text:enabled:hover {
  background: rgba(187, 187, 187, 0);
  border-color: transparent;
  color: #bbb;
}
.p-button.p-button-secondary.p-button-text:enabled:active, .p-buttonset.p-button-secondary > .p-button.p-button-text:enabled:active, .p-splitbutton.p-button-secondary > .p-button.p-button-text:enabled:active {
  background: rgba(187, 187, 187, 0.16);
  border-color: transparent;
  color: #bbb;
}

.p-button.p-button-info, .p-buttonset.p-button-info > .p-button, .p-splitbutton.p-button-info > .p-button {
  color: #ffffff;
  background: #03a9f4;
  border: 1px solid #03a9f4;
}
.p-button.p-button-info:enabled:hover, .p-buttonset.p-button-info > .p-button:enabled:hover, .p-splitbutton.p-button-info > .p-button:enabled:hover {
  background: #039be5;
  color: #ffffff;
  border-color: #039be5;
}
.p-button.p-button-info:enabled:focus, .p-buttonset.p-button-info > .p-button:enabled:focus, .p-splitbutton.p-button-info > .p-button:enabled:focus {
  box-shadow: 0 0 0 0.2rem #ace4fe;
}
.p-button.p-button-info:enabled:active, .p-buttonset.p-button-info > .p-button:enabled:active, .p-splitbutton.p-button-info > .p-button:enabled:active {
  background: #0288d1;
  color: #ffffff;
  border-color: #0288d1;
}
.p-button.p-button-info.p-button-outlined, .p-buttonset.p-button-info > .p-button.p-button-outlined, .p-splitbutton.p-button-info > .p-button.p-button-outlined {
  background-color: transparent;
  color: #03a9f4;
  border: 1px solid;
}
.p-button.p-button-info.p-button-outlined:enabled:hover, .p-buttonset.p-button-info > .p-button.p-button-outlined:enabled:hover, .p-splitbutton.p-button-info > .p-button.p-button-outlined:enabled:hover {
  background: rgba(3, 169, 244, 0);
  color: #03a9f4;
  border: 1px solid;
}
.p-button.p-button-info.p-button-outlined:enabled:active, .p-buttonset.p-button-info > .p-button.p-button-outlined:enabled:active, .p-splitbutton.p-button-info > .p-button.p-button-outlined:enabled:active {
  background: rgba(3, 169, 244, 0.16);
  color: #03a9f4;
  border: 1px solid;
}
.p-button.p-button-info.p-button-text, .p-buttonset.p-button-info > .p-button.p-button-text, .p-splitbutton.p-button-info > .p-button.p-button-text {
  background-color: transparent;
  color: #03a9f4;
  border-color: transparent;
}
.p-button.p-button-info.p-button-text:enabled:hover, .p-buttonset.p-button-info > .p-button.p-button-text:enabled:hover, .p-splitbutton.p-button-info > .p-button.p-button-text:enabled:hover {
  background: rgba(3, 169, 244, 0);
  border-color: transparent;
  color: #03a9f4;
}
.p-button.p-button-info.p-button-text:enabled:active, .p-buttonset.p-button-info > .p-button.p-button-text:enabled:active, .p-splitbutton.p-button-info > .p-button.p-button-text:enabled:active {
  background: rgba(3, 169, 244, 0.16);
  border-color: transparent;
  color: #03a9f4;
}

.p-button.p-button-success, .p-buttonset.p-button-success > .p-button, .p-splitbutton.p-button-success > .p-button {
  color: #ffffff;
  background: #4caf50;
  border: 1px solid #4caf50;
}
.p-button.p-button-success:enabled:hover, .p-buttonset.p-button-success > .p-button:enabled:hover, .p-splitbutton.p-button-success > .p-button:enabled:hover {
  background: #43a047;
  color: #ffffff;
  border-color: #43a047;
}
.p-button.p-button-success:enabled:focus, .p-buttonset.p-button-success > .p-button:enabled:focus, .p-splitbutton.p-button-success > .p-button:enabled:focus {
  box-shadow: 0 0 0 0.2rem #c7e7c8;
}
.p-button.p-button-success:enabled:active, .p-buttonset.p-button-success > .p-button:enabled:active, .p-splitbutton.p-button-success > .p-button:enabled:active {
  background: #388e3c;
  color: #ffffff;
  border-color: #388e3c;
}
.p-button.p-button-success.p-button-outlined, .p-buttonset.p-button-success > .p-button.p-button-outlined, .p-splitbutton.p-button-success > .p-button.p-button-outlined {
  background-color: transparent;
  color: #4caf50;
  border: 1px solid;
}
.p-button.p-button-success.p-button-outlined:enabled:hover, .p-buttonset.p-button-success > .p-button.p-button-outlined:enabled:hover, .p-splitbutton.p-button-success > .p-button.p-button-outlined:enabled:hover {
  background: rgba(76, 175, 80, 0);
  color: #4caf50;
  border: 1px solid;
}
.p-button.p-button-success.p-button-outlined:enabled:active, .p-buttonset.p-button-success > .p-button.p-button-outlined:enabled:active, .p-splitbutton.p-button-success > .p-button.p-button-outlined:enabled:active {
  background: rgba(76, 175, 80, 0.16);
  color: #4caf50;
  border: 1px solid;
}
.p-button.p-button-success.p-button-text, .p-buttonset.p-button-success > .p-button.p-button-text, .p-splitbutton.p-button-success > .p-button.p-button-text {
  background-color: transparent;
  color: #4caf50;
  border-color: transparent;
}
.p-button.p-button-success.p-button-text:enabled:hover, .p-buttonset.p-button-success > .p-button.p-button-text:enabled:hover, .p-splitbutton.p-button-success > .p-button.p-button-text:enabled:hover {
  background: rgba(76, 175, 80, 0);
  border-color: transparent;
  color: #4caf50;
}
.p-button.p-button-success.p-button-text:enabled:active, .p-buttonset.p-button-success > .p-button.p-button-text:enabled:active, .p-splitbutton.p-button-success > .p-button.p-button-text:enabled:active {
  background: rgba(76, 175, 80, 0.16);
  border-color: transparent;
  color: #4caf50;
}

.p-button.p-button-warning, .p-buttonset.p-button-warning > .p-button, .p-splitbutton.p-button-warning > .p-button {
  color: #495057;
  background: #ffc107;
  border: 1px solid #ffc107;
}
.p-button.p-button-warning:enabled:hover, .p-buttonset.p-button-warning > .p-button:enabled:hover, .p-splitbutton.p-button-warning > .p-button:enabled:hover {
  background: #ffb300;
  color: #495057;
  border-color: #ffb300;
}
.p-button.p-button-warning:enabled:focus, .p-buttonset.p-button-warning > .p-button:enabled:focus, .p-splitbutton.p-button-warning > .p-button:enabled:focus {
  box-shadow: 0 0 0 0.2rem #ffeeba;
}
.p-button.p-button-warning:enabled:active, .p-buttonset.p-button-warning > .p-button:enabled:active, .p-splitbutton.p-button-warning > .p-button:enabled:active {
  background: #ffa000;
  color: #495057;
  border-color: #ffa000;
}
.p-button.p-button-warning.p-button-outlined, .p-buttonset.p-button-warning > .p-button.p-button-outlined, .p-splitbutton.p-button-warning > .p-button.p-button-outlined {
  background-color: transparent;
  color: #ffc107;
  border: 1px solid;
}
.p-button.p-button-warning.p-button-outlined:enabled:hover, .p-buttonset.p-button-warning > .p-button.p-button-outlined:enabled:hover, .p-splitbutton.p-button-warning > .p-button.p-button-outlined:enabled:hover {
  background: rgba(255, 193, 7, 0);
  color: #ffc107;
  border: 1px solid;
}
.p-button.p-button-warning.p-button-outlined:enabled:active, .p-buttonset.p-button-warning > .p-button.p-button-outlined:enabled:active, .p-splitbutton.p-button-warning > .p-button.p-button-outlined:enabled:active {
  background: rgba(255, 193, 7, 0.16);
  color: #ffc107;
  border: 1px solid;
}
.p-button.p-button-warning.p-button-text, .p-buttonset.p-button-warning > .p-button.p-button-text, .p-splitbutton.p-button-warning > .p-button.p-button-text {
  background-color: transparent;
  color: #ffc107;
  border-color: transparent;
}
.p-button.p-button-warning.p-button-text:enabled:hover, .p-buttonset.p-button-warning > .p-button.p-button-text:enabled:hover, .p-splitbutton.p-button-warning > .p-button.p-button-text:enabled:hover {
  background: rgba(255, 193, 7, 0);
  border-color: transparent;
  color: #ffc107;
}
.p-button.p-button-warning.p-button-text:enabled:active, .p-buttonset.p-button-warning > .p-button.p-button-text:enabled:active, .p-splitbutton.p-button-warning > .p-button.p-button-text:enabled:active {
  background: rgba(255, 193, 7, 0.16);
  border-color: transparent;
  color: #ffc107;
}

.p-button.p-button-help, .p-buttonset.p-button-help > .p-button, .p-splitbutton.p-button-help > .p-button {
  color: #ffffff;
  background: #9c27b0;
  border: 1px solid #9c27b0;
}
.p-button.p-button-help:enabled:hover, .p-buttonset.p-button-help > .p-button:enabled:hover, .p-splitbutton.p-button-help > .p-button:enabled:hover {
  background: #8e24aa;
  color: #ffffff;
  border-color: #8e24aa;
}
.p-button.p-button-help:enabled:focus, .p-buttonset.p-button-help > .p-button:enabled:focus, .p-splitbutton.p-button-help > .p-button:enabled:focus {
  box-shadow: 0 0 0 0.2rem #ce93d8;
}
.p-button.p-button-help:enabled:active, .p-buttonset.p-button-help > .p-button:enabled:active, .p-splitbutton.p-button-help > .p-button:enabled:active {
  background: #7b1fa2;
  color: #ffffff;
  border-color: #7b1fa2;
}
.p-button.p-button-help.p-button-outlined, .p-buttonset.p-button-help > .p-button.p-button-outlined, .p-splitbutton.p-button-help > .p-button.p-button-outlined {
  background-color: transparent;
  color: #9c27b0;
  border: 1px solid;
}
.p-button.p-button-help.p-button-outlined:enabled:hover, .p-buttonset.p-button-help > .p-button.p-button-outlined:enabled:hover, .p-splitbutton.p-button-help > .p-button.p-button-outlined:enabled:hover {
  background: rgba(156, 39, 176, 0);
  color: #9c27b0;
  border: 1px solid;
}
.p-button.p-button-help.p-button-outlined:enabled:active, .p-buttonset.p-button-help > .p-button.p-button-outlined:enabled:active, .p-splitbutton.p-button-help > .p-button.p-button-outlined:enabled:active {
  background: rgba(156, 39, 176, 0.16);
  color: #9c27b0;
  border: 1px solid;
}
.p-button.p-button-help.p-button-text, .p-buttonset.p-button-help > .p-button.p-button-text, .p-splitbutton.p-button-help > .p-button.p-button-text {
  background-color: transparent;
  color: #9c27b0;
  border-color: transparent;
}
.p-button.p-button-help.p-button-text:enabled:hover, .p-buttonset.p-button-help > .p-button.p-button-text:enabled:hover, .p-splitbutton.p-button-help > .p-button.p-button-text:enabled:hover {
  background: rgba(156, 39, 176, 0);
  border-color: transparent;
  color: #9c27b0;
}
.p-button.p-button-help.p-button-text:enabled:active, .p-buttonset.p-button-help > .p-button.p-button-text:enabled:active, .p-splitbutton.p-button-help > .p-button.p-button-text:enabled:active {
  background: rgba(156, 39, 176, 0.16);
  border-color: transparent;
  color: #9c27b0;
}

.p-button.p-button-danger, .p-buttonset.p-button-danger > .p-button, .p-splitbutton.p-button-danger > .p-button {
  color: #ffffff;
  background: #f44336;
  border: 1px solid #f44336;
}
.p-button.p-button-danger:enabled:hover, .p-buttonset.p-button-danger > .p-button:enabled:hover, .p-splitbutton.p-button-danger > .p-button:enabled:hover {
  background: #e53935;
  color: #ffffff;
  border-color: #e53935;
}
.p-button.p-button-danger:enabled:focus, .p-buttonset.p-button-danger > .p-button:enabled:focus, .p-splitbutton.p-button-danger > .p-button:enabled:focus {
  box-shadow: 0 0 0 0.2rem #fde1df;
}
.p-button.p-button-danger:enabled:active, .p-buttonset.p-button-danger > .p-button:enabled:active, .p-splitbutton.p-button-danger > .p-button:enabled:active {
  background: #d32f2f;
  color: #ffffff;
  border-color: #d32f2f;
}
.p-button.p-button-danger.p-button-outlined, .p-buttonset.p-button-danger > .p-button.p-button-outlined, .p-splitbutton.p-button-danger > .p-button.p-button-outlined {
  background-color: transparent;
  color: #f44336;
  border: 1px solid;
}
.p-button.p-button-danger.p-button-outlined:enabled:hover, .p-buttonset.p-button-danger > .p-button.p-button-outlined:enabled:hover, .p-splitbutton.p-button-danger > .p-button.p-button-outlined:enabled:hover {
  background: rgba(244, 67, 54, 0);
  color: #f44336;
  border: 1px solid;
}
.p-button.p-button-danger.p-button-outlined:enabled:active, .p-buttonset.p-button-danger > .p-button.p-button-outlined:enabled:active, .p-splitbutton.p-button-danger > .p-button.p-button-outlined:enabled:active {
  background: rgba(244, 67, 54, 0.16);
  color: #f44336;
  border: 1px solid;
}
.p-button.p-button-danger.p-button-text, .p-buttonset.p-button-danger > .p-button.p-button-text, .p-splitbutton.p-button-danger > .p-button.p-button-text {
  background-color: transparent;
  color: #f44336;
  border-color: transparent;
}
.p-button.p-button-danger.p-button-text:enabled:hover, .p-buttonset.p-button-danger > .p-button.p-button-text:enabled:hover, .p-splitbutton.p-button-danger > .p-button.p-button-text:enabled:hover {
  background: rgba(244, 67, 54, 0);
  border-color: transparent;
  color: #f44336;
}
.p-button.p-button-danger.p-button-text:enabled:active, .p-buttonset.p-button-danger > .p-button.p-button-text:enabled:active, .p-splitbutton.p-button-danger > .p-button.p-button-text:enabled:active {
  background: rgba(244, 67, 54, 0.16);
  border-color: transparent;
  color: #f44336;
}

.p-button.p-button-link {
  color: #1f88ca;
  background: transparent;
  border: transparent;
}
.p-button.p-button-link:enabled:hover {
  background: transparent;
  color: #074a74;
  border-color: transparent;
}
.p-button.p-button-link:enabled:hover .p-button-label {
  text-decoration: underline;
}
.p-button.p-button-link:enabled:focus {
  background: transparent;
  box-shadow: 0 0 0 0.2rem #bfd1f6;
  border-color: transparent;
}
.p-button.p-button-link:enabled:active {
  background: transparent;
  color: #1f88ca;
  border-color: transparent;
}

.p-carousel .p-carousel-content .p-carousel-prev,
.p-carousel .p-carousel-content .p-carousel-next {
  width: 2rem;
  height: 2rem;
  color: #808080;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  margin: 0.5rem;
}
.p-carousel .p-carousel-content .p-carousel-prev:enabled:hover,
.p-carousel .p-carousel-content .p-carousel-next:enabled:hover {
  color: #495057;
  border-color: transparent;
  background: #e9ecef;
}
.p-carousel .p-carousel-content .p-carousel-prev:focus,
.p-carousel .p-carousel-content .p-carousel-next:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #bfd1f6;
}
.p-carousel .p-carousel-indicators {
  padding: 1rem;
}
.p-carousel .p-carousel-indicators .p-carousel-indicator {
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
}
.p-carousel .p-carousel-indicators .p-carousel-indicator button {
  background-color: #e9ecef;
  width: 2rem;
  height: 0.5rem;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  border-radius: 0;
}
.p-carousel .p-carousel-indicators .p-carousel-indicator button:hover {
  background: #dee2e6;
}
.p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button {
  background: #1f88ca;
  color: #ffffff;
}

.p-datatable .p-paginator-top {
  border-width: 1px 0 1px 0;
  border-radius: 0;
}
.p-datatable .p-paginator-bottom {
  border-width: 0 0 1px 0;
  border-radius: 0;
}
.p-datatable .p-datatable-header {
  background: #f8f9fa;
  color: #495057;
  border: 1px solid #e9ecef;
  border-width: 0 0 1px 0;
  padding: 1rem 1rem;
  font-weight: 600;
}
.p-datatable .p-datatable-footer {
  background: #f8f9fa;
  color: #495057;
  border: 1px solid #e9ecef;
  border-width: 0 0 1px 0;
  padding: 1rem 1rem;
  font-weight: 600;
}
.p-datatable .p-datatable-thead > tr > th {
  text-align: left;
  padding: 0.5rem 1rem;
  border: 1px solid #e9ecef;
  border-width: 0 0 1px 0;
  font-weight: 600;
  color: #495057;
  background: #f8f9fa;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.p-datatable .p-datatable-tfoot > tr > td {
  text-align: left;
  padding: 1rem 1rem;
  border: 1px solid #e9ecef;
  border-width: 0 0 1px 0;
  font-weight: 600;
  color: #495057;
  background: #f8f9fa;
}
.p-datatable .p-sortable-column {
  outline-color: #bfd1f6;
}
.p-datatable .p-sortable-column .p-sortable-column-icon {
  color: #808080;
  margin-left: 0.5rem;
}
.p-datatable .p-sortable-column .p-sortable-column-badge {
  border-radius: 50%;
  height: 1.143rem;
  min-width: 1.143rem;
  line-height: 1.143rem;
  color: #ffffff;
  background: #1f88ca;
  margin-left: 0.5rem;
}
.p-datatable .p-sortable-column:not(.p-highlight):hover {
  background: #e9ecef;
  color: #495057;
}
.p-datatable .p-sortable-column:not(.p-highlight):hover .p-sortable-column-icon {
  color: #808080;
}
.p-datatable .p-sortable-column.p-highlight {
  background: #f8f9fa;
  color: #1f88ca;
}
.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
  color: #1f88ca;
}
.p-datatable .p-datatable-tbody > tr {
  background: #ffffff;
  color: #495057;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  outline-color: #bfd1f6;
}
.p-datatable .p-datatable-tbody > tr > td {
  text-align: left;
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-width: 0 0 1px 0;
  padding: 1rem 1rem;
}
.p-datatable .p-datatable-tbody > tr > td .p-row-toggler,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-init,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-save,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel {
  width: 2rem;
  height: 2rem;
  color: #808080;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.p-datatable .p-datatable-tbody > tr > td .p-row-toggler:enabled:hover,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-init:enabled:hover,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-save:enabled:hover,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel:enabled:hover {
  color: #495057;
  border-color: transparent;
  background: #e9ecef;
}
.p-datatable .p-datatable-tbody > tr > td .p-row-toggler:focus,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-init:focus,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-save:focus,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #bfd1f6;
}
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-save {
  margin-right: 0.5rem;
}
.p-datatable .p-datatable-tbody > tr.p-highlight {
  background: #1f88ca;
  color: #ffffff;
}
.p-datatable .p-datatable-tbody > tr.p-datatable-dragpoint-top > td {
  box-shadow: inset 0 2px 0 0 #1f88ca;
}
.p-datatable .p-datatable-tbody > tr.p-datatable-dragpoint-bottom > td {
  box-shadow: inset 0 -2px 0 0 #1f88ca;
}
.p-datatable.p-datatable-hoverable-rows .p-datatable-tbody > tr:not(.p-highlight):hover {
  background: #edf5fd;
  color: #495057;
}
.p-datatable .p-column-resizer-helper {
  background: #1f88ca;
}
.p-datatable .p-datatable-scrollable-header,
.p-datatable .p-datatable-scrollable-footer {
  background: #f8f9fa;
}
.p-datatable .p-datatable-loading-icon {
  font-size: 2rem;
}
.p-datatable.p-datatable-gridlines .p-datatable-header {
  border-width: 1px 1px 0 1px;
}
.p-datatable.p-datatable-gridlines .p-datatable-footer {
  border-width: 0 1px 1px 1px;
}
.p-datatable.p-datatable-gridlines .p-paginator-top {
  border-width: 0 1px 0 1px;
}
.p-datatable.p-datatable-gridlines .p-paginator-bottom {
  border-width: 0 1px 1px 1px;
}
.p-datatable.p-datatable-gridlines .p-datatable-thead > tr > th {
  border-width: 1px 1px 1px 1px;
}
.p-datatable.p-datatable-gridlines .p-datatable-tbody > tr > td {
  border-width: 1px;
}
.p-datatable.p-datatable-gridlines .p-datatable-tfoot > tr > td {
  border-width: 1px;
}
.p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even) {
  background: #ffffff;
}
.p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even).p-highlight {
  background: #1f88ca;
  color: #ffffff;
}
.p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even).p-highlight .p-row-toggler {
  color: #ffffff;
}
.p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even).p-highlight .p-row-toggler:hover {
  color: #ffffff;
}
.p-datatable.p-datatable-sm .p-datatable-header {
  padding: 0.5rem 0.5rem;
}
.p-datatable.p-datatable-sm .p-datatable-thead > tr > th {
  padding: 0.25rem 0.5rem;
}
.p-datatable.p-datatable-sm .p-datatable-tbody > tr > td {
  padding: 0.5rem 0.5rem;
}
.p-datatable.p-datatable-sm .p-datatable-tfoot > tr > td {
  padding: 0.5rem 0.5rem;
}
.p-datatable.p-datatable-sm .p-datatable-footer {
  padding: 0.5rem 0.5rem;
}
.p-datatable.p-datatable-lg .p-datatable-header {
  padding: 1.25rem 1.25rem;
}
.p-datatable.p-datatable-lg .p-datatable-thead > tr > th {
  padding: 0.625rem 1.25rem;
}
.p-datatable.p-datatable-lg .p-datatable-tbody > tr > td {
  padding: 1.25rem 1.25rem;
}
.p-datatable.p-datatable-lg .p-datatable-tfoot > tr > td {
  padding: 1.25rem 1.25rem;
}
.p-datatable.p-datatable-lg .p-datatable-footer {
  padding: 1.25rem 1.25rem;
}

.p-dataview .p-paginator-top {
  border-width: 1px 0 1px 0;
  border-radius: 0;
}
.p-dataview .p-paginator-bottom {
  border-width: 0 0 1px 0;
  border-radius: 0;
}
.p-dataview .p-dataview-header {
  background: #f8f9fa;
  color: #495057;
  border: 1px solid #e9ecef;
  border-width: 0 0 1px 0;
  padding: 1rem 1rem;
  font-weight: 600;
}
.p-dataview .p-dataview-content {
  background: #ffffff;
  color: #495057;
  border: 0 none;
  padding: 0;
}
.p-dataview.p-dataview-list .p-dataview-content > .p-grid > div {
  border: solid #e9ecef;
  border-width: 0 0 1px 0;
}
.p-dataview .p-dataview-footer {
  background: #f8f9fa;
  color: #495057;
  border: 1px solid #e9ecef;
  border-width: 0 0 1px 0;
  padding: 1rem 1rem;
  font-weight: 600;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.p-dataview .p-dataview-loading-icon {
  font-size: 2rem;
}
.p-dataview .p-dataview-emptymessage {
  padding: 1rem;
}

.fc .fc-view-container th {
  background: #f8f9fa;
  border: 1px solid #dee2e6;
  color: #495057;
}
.fc .fc-view-container td.fc-widget-content {
  background: #ffffff;
  border: 1px solid #dee2e6;
  color: #495057;
}
.fc .fc-view-container td.fc-head-container {
  border: 1px solid #dee2e6;
}
.fc .fc-view-container .fc-row {
  border-right: 1px solid #dee2e6;
}
.fc .fc-view-container .fc-event {
  background: #0b669f;
  border: 1px solid #0b669f;
  color: #ffffff;
}
.fc .fc-view-container .fc-divider {
  background: #f8f9fa;
  border: 1px solid #dee2e6;
}
.fc .fc-toolbar .fc-button {
  color: #ffffff;
  background: #1f88ca;
  border: 1px solid #1f88ca;
  font-size: 14px;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 8px;
  display: flex;
  align-items: center;
}
.fc .fc-toolbar .fc-button:hover {
  background: #0b669f;
  color: #ffffff;
  border-color: #0b669f;
}
.fc .fc-toolbar .fc-button .fc-icon-chevron-left {
  font-family: "PrimeIcons" !important;
  text-indent: 0;
  font-size: 1rem;
}
.fc .fc-toolbar .fc-button .fc-icon-chevron-left:before {
  content: "";
}
.fc .fc-toolbar .fc-button .fc-icon-chevron-right {
  font-family: "PrimeIcons" !important;
  text-indent: 0;
  font-size: 1rem;
}
.fc .fc-toolbar .fc-button .fc-icon-chevron-right:before {
  content: "";
}
.fc .fc-toolbar .fc-button:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #bfd1f6;
}
.fc .fc-toolbar .fc-button.fc-dayGridMonth-button, .fc .fc-toolbar .fc-button.fc-timeGridWeek-button, .fc .fc-toolbar .fc-button.fc-timeGridDay-button {
  background: #ffffff;
  border: 1px solid #ced4da;
  color: #495057;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.fc .fc-toolbar .fc-button.fc-dayGridMonth-button:hover, .fc .fc-toolbar .fc-button.fc-timeGridWeek-button:hover, .fc .fc-toolbar .fc-button.fc-timeGridDay-button:hover {
  background: #e9ecef;
  border-color: #ced4da;
  color: #495057;
}
.fc .fc-toolbar .fc-button.fc-dayGridMonth-button.fc-button-active, .fc .fc-toolbar .fc-button.fc-timeGridWeek-button.fc-button-active, .fc .fc-toolbar .fc-button.fc-timeGridDay-button.fc-button-active {
  background: #1f88ca;
  border-color: #1f88ca;
  color: #ffffff;
}
.fc .fc-toolbar .fc-button.fc-dayGridMonth-button.fc-button-active:hover, .fc .fc-toolbar .fc-button.fc-timeGridWeek-button.fc-button-active:hover, .fc .fc-toolbar .fc-button.fc-timeGridDay-button.fc-button-active:hover {
  background: #0b669f;
  border-color: #0b669f;
  color: #ffffff;
}
.fc .fc-toolbar .fc-button.fc-dayGridMonth-button:focus, .fc .fc-toolbar .fc-button.fc-timeGridWeek-button:focus, .fc .fc-toolbar .fc-button.fc-timeGridDay-button:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #bfd1f6;
  z-index: 1;
}
.fc .fc-toolbar .fc-button-group .fc-button {
  border-radius: 0;
}
.fc .fc-toolbar .fc-button-group .fc-button:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}
.fc .fc-toolbar .fc-button-group .fc-button:last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.p-orderlist .p-orderlist-controls {
  padding: 1rem;
}
.p-orderlist .p-orderlist-controls .p-button {
  margin-bottom: 0.5rem;
}
.p-orderlist .p-orderlist-header {
  background: #f8f9fa;
  color: #495057;
  border: 1px solid #dee2e6;
  padding: 1rem;
  border-bottom: 0 none;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}
.p-orderlist .p-orderlist-header .p-orderlist-title {
  font-weight: 600;
}
.p-orderlist .p-orderlist-filter-container {
  padding: 1rem;
  background: #ffffff;
  border: 1px solid #dee2e6;
  border-bottom: 0 none;
}
.p-orderlist .p-orderlist-filter-container .p-orderlist-filter-input {
  padding-right: 2rem;
}
.p-orderlist .p-orderlist-filter-container .p-orderlist-filter-icon {
  right: 1rem;
  color: #495057;
}
.p-orderlist .p-orderlist-list {
  border: 1px solid #dee2e6;
  background: #ffffff;
  color: #495057;
  padding: 0.5rem 0;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}
.p-orderlist .p-orderlist-list .p-orderlist-item {
  padding: 0.5rem 1rem;
  margin: 0;
  border: 0 none;
  color: #495057;
  background: transparent;
  transition: transform 0.2s, background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.p-orderlist .p-orderlist-list .p-orderlist-item:not(.p-highlight):hover {
  background: #e9ecef;
  color: #495057;
}
.p-orderlist .p-orderlist-list .p-orderlist-item:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem #bfd1f6;
}
.p-orderlist .p-orderlist-list .p-orderlist-item.p-highlight {
  color: #ffffff;
  background: #1f88ca;
}
.p-orderlist .p-orderlist-list .p-orderlist-droppoint.p-orderlist-droppoint-highlight {
  background-color: #196da2;
}

@media screen and (max-width: 769px) {
  .p-orderlist {
    flex-direction: column;
  }
  .p-orderlist .p-orderlist-controls {
    padding: 1rem;
    flex-direction: row;
  }
  .p-orderlist .p-orderlist-controls .p-button {
    margin-right: 0.5rem;
    margin-bottom: 0;
  }
  .p-orderlist .p-orderlist-controls .p-button:last-child {
    margin-right: 0;
  }
}
.p-organizationchart .p-organizationchart-node-content.p-organizationchart-selectable-node:not(.p-highlight):hover {
  background: #e9ecef;
  color: #495057;
}
.p-organizationchart .p-organizationchart-node-content.p-highlight {
  background: #1f88ca;
  color: #ffffff;
}
.p-organizationchart .p-organizationchart-node-content.p-highlight .p-node-toggler i {
  color: #0e3e5b;
}
.p-organizationchart .p-organizationchart-line-down {
  background: #dee2e6;
}
.p-organizationchart .p-organizationchart-line-left {
  border-right: 1px solid #dee2e6;
  border-color: #dee2e6;
}
.p-organizationchart .p-organizationchart-line-top {
  border-top: 1px solid #dee2e6;
  border-color: #dee2e6;
}
.p-organizationchart .p-organizationchart-node-content {
  border: 1px solid #dee2e6;
  background: #ffffff;
  color: #495057;
  padding: 1rem;
}
.p-organizationchart .p-organizationchart-node-content .p-node-toggler {
  background: inherit;
  color: inherit;
  border-radius: 50%;
}
.p-organizationchart .p-organizationchart-node-content .p-node-toggler:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #bfd1f6;
}

.p-paginator {
  background: #ffffff;
  color: #808080;
  border: solid #e9ecef;
  border-width: 0;
  padding: 0.5rem 1rem;
  border-radius: 8px;
}
.p-paginator .p-paginator-first,
.p-paginator .p-paginator-prev,
.p-paginator .p-paginator-next,
.p-paginator .p-paginator-last {
  background-color: transparent;
  border: 0 none;
  color: #808080;
  min-width: 2.357rem;
  height: 2.357rem;
  margin: 0.143rem;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 8px;
}
.p-paginator .p-paginator-first:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-prev:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-next:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-last:not(.p-disabled):not(.p-highlight):hover {
  background: #e9ecef;
  border-color: transparent;
  color: #808080;
}
.p-paginator .p-paginator-first {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}
.p-paginator .p-paginator-last {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}
.p-paginator .p-dropdown {
  margin-left: 0.5rem;
  height: 2.357rem;
}
.p-paginator .p-dropdown .p-dropdown-label {
  padding-right: 0;
}
.p-paginator .p-paginator-current {
  background-color: transparent;
  border: 0 none;
  color: #808080;
  min-width: 2.357rem;
  height: 2.357rem;
  margin: 0.143rem;
  padding: 0 0.5rem;
}
.p-paginator .p-paginator-pages .p-paginator-page {
  background-color: transparent;
  border: 0 none;
  color: #808080;
  min-width: 2.357rem;
  height: 2.357rem;
  margin: 0.143rem;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 8px;
}
.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background: #1f88ca;
  border-color: #1f88ca;
  color: #ffffff;
}
.p-paginator .p-paginator-pages .p-paginator-page:not(.p-highlight):hover {
  background: #e9ecef;
  border-color: transparent;
  color: #808080;
}

.p-picklist .p-picklist-buttons {
  padding: 1rem;
}
.p-picklist .p-picklist-buttons .p-button {
  margin-bottom: 0.5rem;
}
.p-picklist .p-picklist-header {
  background: #f8f9fa;
  color: #495057;
  border: 1px solid #dee2e6;
  padding: 1rem;
  border-bottom: 0 none;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}
.p-picklist .p-picklist-header .p-picklist-title {
  font-weight: 600;
}
.p-picklist .p-picklist-filter-container {
  padding: 1rem;
  background: #ffffff;
  border: 1px solid #dee2e6;
  border-bottom: 0 none;
}
.p-picklist .p-picklist-filter-container .p-picklist-filter-input {
  padding-right: 2rem;
}
.p-picklist .p-picklist-filter-container .p-picklist-filter-icon {
  right: 1rem;
  color: #495057;
}
.p-picklist .p-picklist-list {
  border: 1px solid #dee2e6;
  background: #ffffff;
  color: #495057;
  padding: 0.5rem 0;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}
.p-picklist .p-picklist-list .p-picklist-item {
  padding: 0.5rem 1rem;
  margin: 0;
  border: 0 none;
  color: #495057;
  background: transparent;
  transition: transform 0.2s, background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.p-picklist .p-picklist-list .p-picklist-item:not(.p-highlight):hover {
  background: #e9ecef;
  color: #495057;
}
.p-picklist .p-picklist-list .p-picklist-item:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem #bfd1f6;
}
.p-picklist .p-picklist-list .p-picklist-item.p-highlight {
  color: #ffffff;
  background: #1f88ca;
}
.p-picklist .p-picklist-list .p-picklist-droppoint.p-picklist-droppoint-highlight {
  background-color: #196da2;
}
.p-picklist .p-picklist-list .p-picklist-empty-message {
  padding: 0.5rem 1rem;
  color: #495057;
}

@media screen and (max-width: 769px) {
  .p-picklist {
    flex-direction: column;
  }
  .p-picklist .p-picklist-buttons {
    padding: 1rem;
    flex-direction: row;
  }
  .p-picklist .p-picklist-buttons .p-button {
    margin-right: 0.5rem;
    margin-bottom: 0;
  }
  .p-picklist .p-picklist-buttons .p-button:last-child {
    margin-right: 0;
  }
  .p-picklist .p-picklist-transfer-buttons .pi-angle-right:before {
    content: "";
  }
  .p-picklist .p-picklist-transfer-buttons .pi-angle-double-right:before {
    content: "";
  }
  .p-picklist .p-picklist-transfer-buttons .pi-angle-left:before {
    content: "";
  }
  .p-picklist .p-picklist-transfer-buttons .pi-angle-double-left:before {
    content: "";
  }
}
.p-tree {
  border: 1px solid #dee2e6;
  background: #ffffff;
  color: #495057;
  padding: 1rem;
  border-radius: 8px;
}
.p-tree .p-tree-container .p-treenode {
  padding: 0.143rem;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content {
  border-radius: 8px;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  padding: 0.5rem;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler {
  margin-right: 0.5rem;
  width: 2rem;
  height: 2rem;
  color: #808080;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler:enabled:hover {
  color: #495057;
  border-color: transparent;
  background: #e9ecef;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #bfd1f6;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content .p-treenode-icon {
  margin-right: 0.5rem;
  color: #808080;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content .p-checkbox {
  margin-right: 0.5rem;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content .p-checkbox .p-indeterminate .p-checkbox-icon {
  color: #495057;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #bfd1f6;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight {
  background: #1f88ca;
  color: #ffffff;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-tree-toggler,
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-treenode-icon {
  color: #ffffff;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-treenode-selectable:not(.p-highlight):hover {
  background: #e9ecef;
  color: #495057;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-treenode-dragover {
  background: #e9ecef;
  color: #495057;
}
.p-tree .p-tree-filter-container {
  margin-bottom: 0.5rem;
}
.p-tree .p-tree-filter-container .p-tree-filter {
  width: 100%;
  padding-right: 2rem;
}
.p-tree .p-tree-filter-container .p-tree-filter-icon {
  right: 1rem;
  color: #495057;
}
.p-tree .p-treenode-children {
  padding: 0 0 0 1rem;
}
.p-tree .p-tree-loading-icon {
  font-size: 2rem;
}
.p-tree .p-treenode-droppoint.p-treenode-droppoint-active {
  background-color: #196da2;
}
.p-tree.p-tree-horizontal .p-treenode .p-treenode-content {
  border-radius: 8px;
  border: 1px solid #dee2e6;
  background-color: #ffffff;
  color: #495057;
  padding: 0.5rem;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.p-tree.p-tree-horizontal .p-treenode .p-treenode-content.p-highlight {
  background-color: #1f88ca;
  color: #ffffff;
}
.p-tree.p-tree-horizontal .p-treenode .p-treenode-content.p-highlight .p-treenode-icon {
  color: #ffffff;
}
.p-tree.p-tree-horizontal .p-treenode .p-treenode-content .p-tree-toggler {
  margin-right: 0.5rem;
}
.p-tree.p-tree-horizontal .p-treenode .p-treenode-content .p-treenode-icon {
  color: #808080;
  margin-right: 0.5rem;
}
.p-tree.p-tree-horizontal .p-treenode .p-treenode-content .p-checkbox {
  margin-right: 0.5rem;
}
.p-tree.p-tree-horizontal .p-treenode .p-treenode-content .p-checkbox .p-checkbox-icon {
  color: #1f88ca;
}
.p-tree.p-tree-horizontal .p-treenode .p-treenode-content .p-treenode-label:not(.p-highlight):hover {
  background-color: inherit;
  color: inherit;
}
.p-tree.p-tree-horizontal .p-treenode .p-treenode-content.p-treenode-selectable:not(.p-highlight):hover {
  background: #e9ecef;
  color: #495057;
}
.p-tree.p-tree-horizontal .p-treenode .p-treenode-content:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #bfd1f6;
}

.p-treetable .p-paginator-top {
  border-width: 1px 0 1px 0;
  border-radius: 0;
}
.p-treetable .p-paginator-bottom {
  border-width: 0 0 1px 0;
  border-radius: 0;
}
.p-treetable .p-treetable-header {
  background: #f8f9fa;
  color: #495057;
  border: 1px solid #e9ecef;
  border-width: 0 0 1px 0;
  padding: 1rem 1rem;
  font-weight: 600;
}
.p-treetable .p-treetable-footer {
  background: #f8f9fa;
  color: #495057;
  border: 1px solid #e9ecef;
  border-width: 0 0 1px 0;
  padding: 1rem 1rem;
  font-weight: 600;
}
.p-treetable .p-treetable-thead > tr > th {
  text-align: left;
  padding: 0.5rem 1rem;
  border: 1px solid #e9ecef;
  border-width: 0 0 1px 0;
  font-weight: 600;
  color: #495057;
  background: #f8f9fa;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.p-treetable .p-treetable-tfoot > tr > td {
  text-align: left;
  padding: 1rem 1rem;
  border: 1px solid #e9ecef;
  border-width: 0 0 1px 0;
  font-weight: 600;
  color: #495057;
  background: #f8f9fa;
}
.p-treetable .p-sortable-column {
  outline-color: #bfd1f6;
}
.p-treetable .p-sortable-column .p-sortable-column-icon {
  color: #808080;
  margin-left: 0.5rem;
}
.p-treetable .p-sortable-column .p-sortable-column-badge {
  border-radius: 50%;
  height: 1.143rem;
  min-width: 1.143rem;
  line-height: 1.143rem;
  color: #ffffff;
  background: #1f88ca;
  margin-left: 0.5rem;
}
.p-treetable .p-sortable-column:not(.p-highlight):hover {
  background: #e9ecef;
  color: #495057;
}
.p-treetable .p-sortable-column:not(.p-highlight):hover .p-sortable-column-icon {
  color: #808080;
}
.p-treetable .p-sortable-column.p-highlight {
  background: #f8f9fa;
  color: #1f88ca;
}
.p-treetable .p-sortable-column.p-highlight .p-sortable-column-icon {
  color: #1f88ca;
}
.p-treetable .p-treetable-tbody > tr {
  background: #ffffff;
  color: #495057;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  outline-color: #bfd1f6;
}
.p-treetable .p-treetable-tbody > tr > td {
  text-align: left;
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-width: 0 0 1px 0;
  padding: 1rem 1rem;
}
.p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler {
  width: 2rem;
  height: 2rem;
  color: #808080;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  margin-right: 0.5rem;
}
.p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler:enabled:hover {
  color: #495057;
  border-color: transparent;
  background: #e9ecef;
}
.p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #bfd1f6;
}
.p-treetable .p-treetable-tbody > tr > td p-treetablecheckbox .p-checkbox {
  margin-right: 0.5rem;
}
.p-treetable .p-treetable-tbody > tr > td p-treetablecheckbox .p-checkbox .p-indeterminate .p-checkbox-icon {
  color: #495057;
}
.p-treetable .p-treetable-tbody > tr.p-highlight {
  background: #1f88ca;
  color: #ffffff;
}
.p-treetable .p-treetable-tbody > tr.p-highlight .p-treetable-toggler {
  color: #ffffff;
}
.p-treetable .p-treetable-tbody > tr.p-highlight .p-treetable-toggler:hover {
  color: #ffffff;
}
.p-treetable.p-treetable-hoverable-rows .p-treetable-tbody > tr:not(.p-highlight):hover {
  background: #edf5fd;
  color: #495057;
}
.p-treetable.p-treetable-hoverable-rows .p-treetable-tbody > tr:not(.p-highlight):hover .p-treetable-toggler {
  color: #495057;
}
.p-treetable .p-column-resizer-helper {
  background: #1f88ca;
}
.p-treetable .p-treetable-scrollable-header,
.p-treetable .p-treetable-scrollable-footer {
  background: #f8f9fa;
}
.p-treetable .p-treetable-loading-icon {
  font-size: 2rem;
}
.p-treetable.p-treetable-gridlines .p-datatable-header {
  border-width: 1px 1px 0 1px;
}
.p-treetable.p-treetable-gridlines .p-treetable-footer {
  border-width: 0 1px 1px 1px;
}
.p-treetable.p-treetable-gridlines .p-treetable-top {
  border-width: 0 1px 0 1px;
}
.p-treetable.p-treetable-gridlines .p-treetable-bottom {
  border-width: 0 1px 1px 1px;
}
.p-treetable.p-treetable-gridlines .p-treetable-thead > tr > th {
  border-width: 1px;
}
.p-treetable.p-treetable-gridlines .p-treetable-tbody > tr > td {
  border-width: 1px;
}
.p-treetable.p-treetable-gridlines .p-treetable-tfoot > tr > td {
  border-width: 1px;
}
.p-treetable.p-treetable-sm .p-treetable-header {
  padding: 0.875rem 0.875rem;
}
.p-treetable.p-treetable-sm .p-treetable-thead > tr > th {
  padding: 0.25rem 0.5rem;
}
.p-treetable.p-treetable-sm .p-treetable-tbody > tr > td {
  padding: 0.5rem 0.5rem;
}
.p-treetable.p-treetable-sm .p-treetable-tfoot > tr > td {
  padding: 0.5rem 0.5rem;
}
.p-treetable.p-treetable-sm .p-treetable-footer {
  padding: 0.5rem 0.5rem;
}
.p-treetable.p-treetable-lg .p-treetable-header {
  padding: 1.25rem 1.25rem;
}
.p-treetable.p-treetable-lg .p-treetable-thead > tr > th {
  padding: 0.625rem 1.25rem;
}
.p-treetable.p-treetable-lg .p-treetable-tbody > tr > td {
  padding: 1.25rem 1.25rem;
}
.p-treetable.p-treetable-lg .p-treetable-tfoot > tr > td {
  padding: 1.25rem 1.25rem;
}
.p-treetable.p-treetable-lg .p-treetable-footer {
  padding: 1.25rem 1.25rem;
}

.p-virtualscroller .p-virtualscroller-header {
  background: #f8f9fa;
  color: #495057;
  border: 1px solid #e9ecef;
  border-width: 0 0 1px 0;
  padding: 1rem 1rem;
  font-weight: 600;
}
.p-virtualscroller .p-virtualscroller-content {
  background: #ffffff;
  color: #495057;
  border: 0 none;
  padding: 0;
}
.p-virtualscroller .p-virtualscroller-footer {
  background: #f8f9fa;
  color: #495057;
  border: 1px solid #e9ecef;
  border-width: 0 0 1px 0;
  padding: 1rem 1rem;
  font-weight: 600;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.p-accordion .p-accordion-header .p-accordion-header-link {
  padding: 1rem;
  border: 1px solid #dee2e6;
  color: #495057;
  background: #f8f9fa;
  font-weight: 600;
  border-radius: 8px;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.p-accordion .p-accordion-header .p-accordion-header-link .p-accordion-toggle-icon {
  margin-right: 0.5rem;
}
.p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #bfd1f6;
}
.p-accordion .p-accordion-header:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link {
  background: #e9ecef;
  border-color: 1px solid #dee2e6;
  color: #495057;
}
.p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
  background: #f8f9fa;
  border-color: #dee2e6;
  color: #495057;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.p-accordion .p-accordion-header:not(.p-disabled).p-highlight:hover .p-accordion-header-link {
  border-color: #dee2e6;
  background: #e9ecef;
  color: #495057;
}
.p-accordion .p-accordion-content {
  padding: 1rem;
  border: 1px solid #dee2e6;
  background: #ffffff;
  color: #495057;
  border-top: 0;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}
.p-accordion p-accordiontab .p-accordion-tab {
  margin-bottom: 0;
}
.p-accordion p-accordiontab .p-accordion-header .p-accordion-header-link {
  border-radius: 0;
}
.p-accordion p-accordiontab .p-accordion-content {
  border-radius: 0;
}
.p-accordion p-accordiontab:not(:first-child) .p-accordion-header .p-accordion-header-link {
  border-top: 0 none;
}
.p-accordion p-accordiontab:not(:first-child) .p-accordion-header:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link, .p-accordion p-accordiontab:not(:first-child) .p-accordion-header:not(.p-disabled).p-highlight:hover .p-accordion-header-link {
  border-top: 0 none;
}
.p-accordion p-accordiontab:first-child .p-accordion-header .p-accordion-header-link {
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}
.p-accordion p-accordiontab:last-child .p-accordion-header:not(.p-highlight) .p-accordion-header-link {
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}
.p-accordion p-accordiontab:last-child .p-accordion-content {
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}

.p-card {
  background: #ffffff;
  color: #495057;
  box-shadow: 0 9px 13px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
}
.p-card .p-card-body {
  padding: 1.5rem;
}
.p-card .p-card-title {
  font-size: 1.15rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
}
.p-card .p-card-subtitle {
  font-weight: 700;
  margin-bottom: 0.5rem;
  color: #808080;
}
.p-card .p-card-content {
  padding: 0;
}
.p-card .p-card-footer {
  padding: 1rem 0 0 0;
}

.p-fieldset {
  border: 1px solid #dee2e6;
  background: #ffffff;
  color: #495057;
  border-radius: 8px;
}
.p-fieldset .p-fieldset-legend {
  padding: 1rem;
  border: 1px solid #dee2e6;
  color: #495057;
  background: #f8f9fa;
  font-weight: 600;
  border-radius: 8px;
}
.p-fieldset.p-fieldset-toggleable .p-fieldset-legend {
  padding: 0;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.p-fieldset.p-fieldset-toggleable .p-fieldset-legend a {
  padding: 1rem;
  color: #495057;
  border-radius: 8px;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.p-fieldset.p-fieldset-toggleable .p-fieldset-legend a .p-fieldset-toggler {
  margin-right: 0.5rem;
}
.p-fieldset.p-fieldset-toggleable .p-fieldset-legend a:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #bfd1f6;
}
.p-fieldset.p-fieldset-toggleable .p-fieldset-legend:hover {
  background: #e9ecef;
  border-color: #dee2e6;
  color: #495057;
}

.p-panel .p-panel-header {
  border: 1px solid #dee2e6;
  padding: 1rem;
  background: #f8f9fa;
  color: #495057;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}
.p-panel .p-panel-header .p-panel-title {
  font-weight: 600;
}
.p-panel .p-panel-header .p-panel-header-icon {
  width: 2rem;
  height: 2rem;
  color: #808080;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.p-panel .p-panel-header .p-panel-header-icon:enabled:hover {
  color: #495057;
  border-color: transparent;
  background: #e9ecef;
}
.p-panel .p-panel-header .p-panel-header-icon:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #bfd1f6;
}
.p-panel.p-panel-toggleable .p-panel-header {
  padding: 0.5rem 1rem;
}
.p-panel .p-panel-content {
  padding: 1rem;
  border: 1px solid #dee2e6;
  background: #ffffff;
  color: #495057;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  border-top: 0 none;
}
.p-panel .p-panel-footer {
  padding: 0.5rem 1rem;
  border: 1px solid #dee2e6;
  background: #ffffff;
  color: #495057;
  border-top: 0 none;
}

.p-scrollpanel .p-scrollpanel-bar {
  background: #f8f9fa;
  border: 0 none;
}

.p-tabview .p-tabview-nav {
  background: #ffffff;
  border: 1px solid #dee2e6;
  border-width: 0 0 2px 0;
}
.p-tabview .p-tabview-nav li {
  margin-right: 0;
}
.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  border: solid #dee2e6;
  border-width: 0 0 2px 0;
  border-color: transparent transparent #dee2e6 transparent;
  background: #ffffff;
  color: #808080;
  padding: 1rem;
  font-weight: 600;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  margin: 0 0 -2px 0;
}
.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #bfd1f6;
}
.p-tabview .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link {
  background: #ffffff;
  border-color: #9ba2aa;
  color: #808080;
}
.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  background: #ffffff;
  border-color: #1f88ca;
  color: #1f88ca;
}
.p-tabview .p-tabview-left-icon {
  margin-right: 0.5rem;
}
.p-tabview .p-tabview-right-icon {
  margin-left: 0.5rem;
}
.p-tabview .p-tabview-close {
  margin-left: 0.5rem;
}
.p-tabview .p-tabview-panels {
  background: #ffffff;
  padding: 1rem;
  border: 0 none;
  color: #495057;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}

.p-toolbar {
  background: #f8f9fa;
  border: 1px solid #dee2e6;
  padding: 1rem;
  border-radius: 8px;
}
.p-toolbar .p-toolbar-separator {
  margin: 0 0.5rem;
}

.p-dialog {
  border-radius: 8px;
  box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.1);
  border: 0 none;
}
.p-dialog .p-dialog-header {
  border-bottom: 1px solid #dee2e6;
  background: #ffffff;
  color: #495057;
  padding: 1rem 1.5rem;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}
.p-dialog .p-dialog-header .p-dialog-title {
  font-weight: inherit;
  font-size: 1.75rem;
}
.p-dialog .p-dialog-header .p-dialog-header-icon {
  width: 2rem;
  height: 2rem;
  color: #808080;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  margin-right: 0.5rem;
}
.p-dialog .p-dialog-header .p-dialog-header-icon:enabled:hover {
  color: #495057;
  border-color: transparent;
  background: #e9ecef;
}
.p-dialog .p-dialog-header .p-dialog-header-icon:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #bfd1f6;
}
.p-dialog .p-dialog-header .p-dialog-header-icon:last-child {
  margin-right: 0;
}
.p-dialog .p-dialog-content {
  background: #ffffff;
  color: #495057;
  padding: 0.5rem 1.5rem 110px 1.5rem;
}
.p-dialog.has-tabs .p-dialog-content {
  padding-top: 0;
}
.p-dialog .p-dialog-footer {
  border-top: 1px solid #dee2e6;
  background: #ffffff;
  color: #495057;
  padding: 1.5rem;
  text-align: right;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}
.p-dialog .p-dialog-footer button {
  margin: 0 0.5rem 0 0;
  width: auto;
}
.p-dialog.p-confirm-dialog .p-confirm-dialog-icon {
  font-size: 2rem;
}
.p-dialog.p-confirm-dialog .p-confirm-dialog-message {
  margin-left: 1rem;
}

.p-dialog-mask.p-component-overlay {
  background-color: rgba(0, 0, 0, 0.4);
}

.p-overlaypanel {
  background: #ffffff;
  color: #495057;
  border: 0 none;
  border-radius: 8px;
  box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.1);
}
.p-overlaypanel .p-overlaypanel-content {
  padding: 1rem;
}
.p-overlaypanel .p-overlaypanel-close {
  background: #1f88ca;
  color: #ffffff;
  width: 2rem;
  height: 2rem;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  border-radius: 50%;
  position: absolute;
  top: -1rem;
  right: -1rem;
}
.p-overlaypanel .p-overlaypanel-close:enabled:hover {
  background: #0b669f;
  color: #ffffff;
}
.p-overlaypanel:after {
  border: solid transparent;
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #ffffff;
}
.p-overlaypanel:before {
  border: solid transparent;
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #ffffff;
}
.p-overlaypanel.p-overlaypanel-flipped:after {
  border-top-color: #ffffff;
}
.p-overlaypanel.p-overlaypanel-flipped:before {
  border-top-color: #ffffff;
}

.p-sidebar {
  background: #ffffff;
  color: #495057;
  padding: 1rem;
  border: 0 none;
  box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.1);
}
.p-sidebar .p-sidebar-close {
  width: 2rem;
  height: 2rem;
  color: #808080;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.p-sidebar .p-sidebar-close:enabled:hover {
  color: #495057;
  border-color: transparent;
  background: #e9ecef;
}
.p-sidebar .p-sidebar-close:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #bfd1f6;
}

.p-sidebar-mask.p-component-overlay {
  background: rgba(0, 0, 0, 0.4);
}

.p-tooltip .p-tooltip-text {
  background: #495057;
  color: #ffffff;
  padding: 0.75rem 1rem;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}
.p-tooltip.p-tooltip-right .p-tooltip-arrow {
  border-right-color: #495057;
}
.p-tooltip.p-tooltip-left .p-tooltip-arrow {
  border-left-color: #495057;
}
.p-tooltip.p-tooltip-top .p-tooltip-arrow {
  border-top-color: #495057;
}
.p-tooltip.p-tooltip-bottom .p-tooltip-arrow {
  border-bottom-color: #495057;
}

.p-fileupload .p-fileupload-buttonbar {
  background: #f8f9fa;
  padding: 1rem;
  border: 1px solid #dee2e6;
  color: #495057;
  border-bottom: 0 none;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}
.p-fileupload .p-fileupload-buttonbar .p-button {
  margin-right: 0.5rem;
}
.p-fileupload .p-fileupload-buttonbar .p-button.p-fileupload-choose.p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #bfd1f6;
}
.p-fileupload .p-fileupload-content {
  background: #ffffff;
  padding: 2rem 1rem;
  border: 1px solid #dee2e6;
  color: #495057;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}
.p-fileupload .p-progressbar {
  height: 0.25rem;
}
.p-fileupload .p-fileupload-row > div {
  padding: 1rem 1rem;
}
.p-fileupload.p-fileupload-advanced .p-message {
  margin-top: 0;
}

.p-fileupload-choose:not(.p-disabled):hover {
  background: #0b669f;
  color: #ffffff;
  border-color: #0b669f;
}
.p-fileupload-choose:not(.p-disabled):active {
  background: #074a74;
  color: #ffffff;
  border-color: #074a74;
}

.p-breadcrumb {
  background: #ffffff;
  border: 1px solid #dee2e6;
  border-radius: 8px;
  padding: 1rem;
}
.p-breadcrumb ul li .p-menuitem-link {
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 8px;
}
.p-breadcrumb ul li .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #bfd1f6;
}
.p-breadcrumb ul li .p-menuitem-link .p-menuitem-text {
  color: #495057;
}
.p-breadcrumb ul li .p-menuitem-link .p-menuitem-icon {
  color: #808080;
}
.p-breadcrumb ul li.p-breadcrumb-chevron {
  margin: 0 0.5rem 0 0.5rem;
  color: #495057;
}
.p-breadcrumb ul li:last-child .p-menuitem-text {
  color: #495057;
}
.p-breadcrumb ul li:last-child .p-menuitem-icon {
  color: #808080;
}

.p-contextmenu {
  padding: 0.25rem 0;
  background: #ffffff;
  color: #495057;
  border: 0 none;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.1);
  width: 12.5rem;
}
.p-contextmenu .p-menuitem-link {
  padding: 0.75rem 1rem;
  color: #495057;
  border-radius: 0;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  user-select: none;
}
.p-contextmenu .p-menuitem-link .p-menuitem-text {
  color: #495057;
}
.p-contextmenu .p-menuitem-link .p-menuitem-icon {
  color: #808080;
  margin-right: 0.5rem;
}
.p-contextmenu .p-menuitem-link .p-submenu-icon {
  color: #808080;
}
.p-contextmenu .p-menuitem-link:not(.p-disabled):hover {
  background: #e9ecef;
}
.p-contextmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #495057;
}
.p-contextmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: #808080;
}
.p-contextmenu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #808080;
}
.p-contextmenu .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem #bfd1f6;
}
.p-contextmenu .p-submenu-list {
  padding: 0.25rem 0;
  background: #ffffff;
  border: 0 none;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.1);
}
.p-contextmenu .p-menuitem.p-menuitem-active > .p-menuitem-link {
  background: #e9ecef;
}
.p-contextmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
  color: #495057;
}
.p-contextmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon, .p-contextmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon {
  color: #808080;
}
.p-contextmenu .p-menu-separator {
  border-top: 1px solid #dee2e6;
  margin: 0.25rem 0;
}
.p-contextmenu .p-submenu-icon {
  font-size: 0.875rem;
}

.p-megamenu {
  padding: 0.5rem;
  background: #f8f9fa;
  color: #495057;
  border: 1px solid #dee2e6;
  border-radius: 8px;
}
.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link {
  padding: 0.75rem 1rem;
  color: #495057;
  border-radius: 8px;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  user-select: none;
}
.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-text {
  color: #495057;
}
.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-icon {
  color: #808080;
  margin-right: 0.5rem;
}
.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link .p-submenu-icon {
  color: #808080;
  margin-left: 0.5rem;
}
.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover {
  background: #e9ecef;
}
.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #495057;
}
.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: #808080;
}
.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #808080;
}
.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem #bfd1f6;
}
.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link,
.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover {
  background: #e9ecef;
}
.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text,
.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #495057;
}
.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon,
.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: #808080;
}
.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon,
.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #808080;
}
.p-megamenu .p-menuitem-link {
  padding: 0.75rem 1rem;
  color: #495057;
  border-radius: 0;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  user-select: none;
}
.p-megamenu .p-menuitem-link .p-menuitem-text {
  color: #495057;
}
.p-megamenu .p-menuitem-link .p-menuitem-icon {
  color: #808080;
  margin-right: 0.5rem;
}
.p-megamenu .p-menuitem-link .p-submenu-icon {
  color: #808080;
}
.p-megamenu .p-menuitem-link:not(.p-disabled):hover {
  background: #e9ecef;
}
.p-megamenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #495057;
}
.p-megamenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: #808080;
}
.p-megamenu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #808080;
}
.p-megamenu .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem #bfd1f6;
}
.p-megamenu .p-megamenu-panel {
  background: #ffffff;
  color: #495057;
  border: 0 none;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.1);
}
.p-megamenu .p-megamenu-submenu-header {
  margin: 0;
  padding: 0.75rem 1rem;
  color: #495057;
  background: #ffffff;
  font-weight: 600;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}
.p-megamenu .p-megamenu-submenu {
  padding: 0.25rem 0;
  width: 12.5rem;
}
.p-megamenu .p-megamenu-submenu .p-menu-separator {
  border-top: 1px solid #dee2e6;
  margin: 0.25rem 0;
}
.p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link {
  background: #e9ecef;
}
.p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
  color: #495057;
}
.p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon, .p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon {
  color: #808080;
}
.p-megamenu.p-megamenu-vertical {
  width: 12.5rem;
  padding: 0.25rem 0;
}

.p-menu {
  padding: 0.25rem 0;
  background: #ffffff;
  color: #495057;
  border: 1px solid #dee2e6;
  border-radius: 8px;
  width: 12.5rem;
}
.p-menu .p-menuitem-link {
  padding: 0.75rem 1rem;
  color: #495057;
  border-radius: 0;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  user-select: none;
}
.p-menu .p-menuitem-link .p-menuitem-text {
  color: #495057;
}
.p-menu .p-menuitem-link .p-menuitem-icon {
  color: #808080;
  margin-right: 0.5rem;
}
.p-menu .p-menuitem-link .p-submenu-icon {
  color: #808080;
}
.p-menu .p-menuitem-link:not(.p-disabled):hover {
  background: #e9ecef;
}
.p-menu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #495057;
}
.p-menu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: #808080;
}
.p-menu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #808080;
}
.p-menu .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem #bfd1f6;
}
.p-menu.p-menu-overlay {
  background: #ffffff;
  border: 0 none;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.1);
}
.p-menu .p-submenu-header {
  margin: 0;
  padding: 0.75rem 1rem;
  color: #495057;
  background: #ffffff;
  font-weight: 600;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
.p-menu .p-menu-separator {
  border-top: 1px solid #dee2e6;
  margin: 0.25rem 0;
}

.p-menubar {
  padding: 0.5rem;
  background: #f8f9fa;
  color: #495057;
  border: 1px solid #dee2e6;
  border-radius: 8px;
}
.p-menubar .p-menuitem-link {
  padding: 0.75rem 1rem;
  color: #495057;
  border-radius: 0;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  user-select: none;
}
.p-menubar .p-menuitem-link .p-menuitem-text {
  color: #495057;
}
.p-menubar .p-menuitem-link .p-menuitem-icon {
  color: #808080;
  margin-right: 0.5rem;
}
.p-menubar .p-menuitem-link .p-submenu-icon {
  color: #808080;
}
.p-menubar .p-menuitem-link:not(.p-disabled):hover {
  background: #e9ecef;
}
.p-menubar .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #495057;
}
.p-menubar .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: #808080;
}
.p-menubar .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #808080;
}
.p-menubar .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem #bfd1f6;
}
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link {
  padding: 0.75rem 1rem;
  color: #495057;
  border-radius: 8px;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  user-select: none;
}
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-text {
  color: #495057;
}
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-icon {
  color: #808080;
  margin-right: 0.5rem;
}
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-submenu-icon {
  color: #808080;
  margin-left: 0.5rem;
}
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover {
  background: #e9ecef;
}
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #495057;
}
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: #808080;
}
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #808080;
}
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem #bfd1f6;
}
.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link,
.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover {
  background: #e9ecef;
}
.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text,
.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #495057;
}
.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon,
.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: #808080;
}
.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon,
.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #808080;
}
.p-menubar .p-submenu-list {
  padding: 0.25rem 0;
  background: #ffffff;
  border: 0 none;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.1);
  width: 12.5rem;
}
.p-menubar .p-submenu-list .p-menu-separator {
  border-top: 1px solid #dee2e6;
  margin: 0.25rem 0;
}
.p-menubar .p-submenu-list .p-submenu-icon {
  font-size: 0.875rem;
}
.p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link {
  background: #e9ecef;
}
.p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
  color: #495057;
}
.p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon, .p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon {
  color: #808080;
}

@media screen and (max-width: 960px) {
  .p-menubar {
    position: relative;
  }
  .p-menubar .p-menubar-button {
    display: flex;
    width: 2rem;
    height: 2rem;
    color: #808080;
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  }
  .p-menubar .p-menubar-button:hover {
    color: #808080;
    background: #e9ecef;
  }
  .p-menubar .p-menubar-button:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #bfd1f6;
  }
  .p-menubar .p-menubar-root-list {
    position: absolute;
    display: none;
    padding: 0.25rem 0;
    background: #ffffff;
    border: 0 none;
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.1);
    width: 100%;
  }
  .p-menubar .p-menubar-root-list .p-menu-separator {
    border-top: 1px solid #dee2e6;
    margin: 0.25rem 0;
  }
  .p-menubar .p-menubar-root-list .p-submenu-icon {
    font-size: 0.875rem;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem {
    width: 100%;
    position: static;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link {
    padding: 0.75rem 1rem;
    color: #495057;
    border-radius: 0;
    transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
    user-select: none;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-text {
    color: #495057;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-icon {
    color: #808080;
    margin-right: 0.5rem;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-submenu-icon {
    color: #808080;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover {
    background: #e9ecef;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
    color: #495057;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
    color: #808080;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
    color: #808080;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 0.15rem #bfd1f6;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link > .p-submenu-icon {
    margin-left: auto;
    transition: transform 0.2s;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link > .p-submenu-icon {
    transform: rotate(-180deg);
  }
  .p-menubar .p-menubar-root-list .p-submenu-list {
    width: 100%;
    position: static;
    box-shadow: none;
    border: 0 none;
  }
  .p-menubar .p-menubar-root-list .p-submenu-list .p-submenu-icon {
    transition: transform 0.2s;
    transform: rotate(90deg);
  }
  .p-menubar .p-menubar-root-list .p-submenu-list .p-menuitem-active > .p-menuitem-link > .p-submenu-icon {
    transform: rotate(-90deg);
  }
  .p-menubar .p-menubar-root-list .p-menuitem {
    width: 100%;
    position: static;
  }
  .p-menubar .p-menubar-root-list ul li a {
    padding-left: 2.25rem;
  }
  .p-menubar .p-menubar-root-list ul li ul li a {
    padding-left: 3.75rem;
  }
  .p-menubar .p-menubar-root-list ul li ul li ul li a {
    padding-left: 5.25rem;
  }
  .p-menubar .p-menubar-root-list ul li ul li ul li ul li a {
    padding-left: 6.75rem;
  }
  .p-menubar .p-menubar-root-list ul li ul li ul li ul li ul li a {
    padding-left: 8.25rem;
  }
  .p-menubar.p-menubar-mobile-active .p-menubar-root-list {
    display: flex;
    flex-direction: column;
    top: 100%;
    left: 0;
    z-index: 1;
  }
}
.p-panelmenu .p-panelmenu-header > a {
  padding: 1rem;
  border: 1px solid #dee2e6;
  color: #495057;
  background: #f8f9fa;
  font-weight: 600;
  border-radius: 8px;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.p-panelmenu .p-panelmenu-header > a .p-panelmenu-icon {
  margin-right: 0.5rem;
}
.p-panelmenu .p-panelmenu-header > a .p-menuitem-icon {
  margin-right: 0.5rem;
}
.p-panelmenu .p-panelmenu-header > a:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #bfd1f6;
}
.p-panelmenu .p-panelmenu-header:not(.p-highlight):not(.p-disabled) > a:hover {
  background: #e9ecef;
  border-color: 1px solid #dee2e6;
  color: #495057;
}
.p-panelmenu .p-panelmenu-header.p-highlight {
  margin-bottom: 0;
}
.p-panelmenu .p-panelmenu-header.p-highlight > a {
  background: #f8f9fa;
  border-color: #dee2e6;
  color: #495057;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.p-panelmenu .p-panelmenu-header.p-highlight:not(.p-disabled) > a:hover {
  border-color: #dee2e6;
  background: #e9ecef;
  color: #495057;
}
.p-panelmenu .p-panelmenu-content {
  padding: 0.25rem 0;
  border: 1px solid #dee2e6;
  background: #ffffff;
  color: #495057;
  margin-bottom: 0;
  border-top: 0;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link {
  padding: 0.75rem 1rem;
  color: #495057;
  border-radius: 0;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  user-select: none;
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-menuitem-text {
  color: #495057;
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-menuitem-icon {
  color: #808080;
  margin-right: 0.5rem;
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-submenu-icon {
  color: #808080;
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover {
  background: #e9ecef;
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #495057;
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: #808080;
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #808080;
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem #bfd1f6;
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-panelmenu-icon {
  margin-right: 0.5rem;
}
.p-panelmenu .p-panelmenu-content .p-submenu-list:not(.p-panelmenu-root-submenu) {
  padding: 0 0 0 1rem;
}
.p-panelmenu .p-panelmenu-panel {
  margin-bottom: 0;
}
.p-panelmenu .p-panelmenu-panel .p-panelmenu-header > a {
  border-radius: 0;
}
.p-panelmenu .p-panelmenu-panel .p-panelmenu-content {
  border-radius: 0;
}
.p-panelmenu .p-panelmenu-panel:not(:first-child) .p-panelmenu-header > a {
  border-top: 0 none;
}
.p-panelmenu .p-panelmenu-panel:not(:first-child) .p-panelmenu-header:not(.p-highlight):not(.p-disabled):hover > a, .p-panelmenu .p-panelmenu-panel:not(:first-child) .p-panelmenu-header:not(.p-disabled).p-highlight:hover > a {
  border-top: 0 none;
}
.p-panelmenu .p-panelmenu-panel:first-child .p-panelmenu-header > a {
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}
.p-panelmenu .p-panelmenu-panel:last-child .p-panelmenu-header:not(.p-highlight) > a {
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}
.p-panelmenu .p-panelmenu-panel:last-child .p-panelmenu-content {
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}

.p-slidemenu {
  padding: 0.25rem 0;
  background: #ffffff;
  color: #495057;
  border: 1px solid #dee2e6;
  border-radius: 8px;
  width: 12.5rem;
}
.p-slidemenu .p-menuitem-link {
  padding: 0.75rem 1rem;
  color: #495057;
  border-radius: 0;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  user-select: none;
}
.p-slidemenu .p-menuitem-link .p-menuitem-text {
  color: #495057;
}
.p-slidemenu .p-menuitem-link .p-menuitem-icon {
  color: #808080;
  margin-right: 0.5rem;
}
.p-slidemenu .p-menuitem-link .p-submenu-icon {
  color: #808080;
}
.p-slidemenu .p-menuitem-link:not(.p-disabled):hover {
  background: #e9ecef;
}
.p-slidemenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #495057;
}
.p-slidemenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: #808080;
}
.p-slidemenu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #808080;
}
.p-slidemenu .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem #bfd1f6;
}
.p-slidemenu.p-slidemenu-overlay {
  background: #ffffff;
  border: 0 none;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.1);
}
.p-slidemenu .p-slidemenu-list {
  padding: 0.25rem 0;
  background: #ffffff;
  border: 0 none;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.1);
}
.p-slidemenu .p-slidemenu.p-slidemenu-active > .p-slidemenu-link {
  background: #e9ecef;
}
.p-slidemenu .p-slidemenu.p-slidemenu-active > .p-slidemenu-link .p-slidemenu-text {
  color: #495057;
}
.p-slidemenu .p-slidemenu.p-slidemenu-active > .p-slidemenu-link .p-slidemenu-icon, .p-slidemenu .p-slidemenu.p-slidemenu-active > .p-slidemenu-link .p-slidemenu-icon {
  color: #808080;
}
.p-slidemenu .p-slidemenu-separator {
  border-top: 1px solid #dee2e6;
  margin: 0.25rem 0;
}
.p-slidemenu .p-slidemenu-icon {
  font-size: 0.875rem;
}
.p-slidemenu .p-slidemenu-backward {
  padding: 0.75rem 1rem;
  color: #495057;
}

.p-steps .p-steps-item .p-menuitem-link {
  background: transparent;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 8px;
  background: #edf1f5;
}
.p-steps .p-steps-item .p-menuitem-link .p-steps-number {
  color: #495057;
  border: 1px solid #c8c8c8;
  background: #edf1f5;
  min-width: 2rem;
  height: 2rem;
  line-height: 2rem;
  font-size: 1em;
  z-index: 1;
  border-radius: 50%;
}
.p-steps .p-steps-item .p-menuitem-link .p-steps-title {
  margin-top: 0.5rem;
  color: #808080;
}
.p-steps .p-steps-item .p-menuitem-link:not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #bfd1f6;
}
.p-steps .p-steps-item.p-highlight .p-steps-number {
  background: #1f88ca;
  color: #ffffff;
}
.p-steps .p-steps-item.p-highlight .p-steps-title {
  font-weight: 600;
  color: #495057;
}
.p-steps .p-steps-item:before {
  content: " ";
  border-top: 1px solid #dee2e6;
  width: 100%;
  top: 50%;
  left: 0;
  display: block;
  position: absolute;
  margin-top: -1rem;
}

.p-tabmenu .p-tabmenu-nav {
  background: #ffffff;
  border: 1px solid #dee2e6;
  border-width: 0 0 2px 0;
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem {
  margin-right: 0;
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
  border: solid #dee2e6;
  border-width: 0 0 2px 0;
  border-color: transparent transparent #dee2e6 transparent;
  background: #ffffff;
  color: #808080;
  padding: 1rem;
  font-weight: 600;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  margin: 0 0 -2px 0;
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link .p-menuitem-icon {
  margin-right: 0.5rem;
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link:not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #bfd1f6;
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem:not(.p-highlight):not(.p-disabled):hover .p-menuitem-link {
  background: #ffffff;
  border-color: #9ba2aa;
  color: #808080;
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
  background: #ffffff;
  border-color: #1f88ca;
  color: #1f88ca;
}

.p-tieredmenu {
  padding: 0.25rem 0;
  background: #ffffff;
  color: #495057;
  border: 1px solid #dee2e6;
  border-radius: 8px;
  width: 12.5rem;
}
.p-tieredmenu .p-menuitem-link {
  padding: 0.75rem 1rem;
  color: #495057;
  border-radius: 0;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  user-select: none;
}
.p-tieredmenu .p-menuitem-link .p-menuitem-text {
  color: #495057;
}
.p-tieredmenu .p-menuitem-link .p-menuitem-icon {
  color: #808080;
  margin-right: 0.5rem;
}
.p-tieredmenu .p-menuitem-link .p-submenu-icon {
  color: #808080;
}
.p-tieredmenu .p-menuitem-link:not(.p-disabled):hover {
  background: #e9ecef;
}
.p-tieredmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #495057;
}
.p-tieredmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: #808080;
}
.p-tieredmenu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #808080;
}
.p-tieredmenu .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem #bfd1f6;
}
.p-tieredmenu.p-tieredmenu-overlay {
  background: #ffffff;
  border: 0 none;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.1);
}
.p-tieredmenu .p-submenu-list {
  padding: 0.25rem 0;
  background: #ffffff;
  border: 0 none;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.1);
}
.p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link {
  background: #e9ecef;
}
.p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
  color: #495057;
}
.p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon, .p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon {
  color: #808080;
}
.p-tieredmenu .p-menu-separator {
  border-top: 1px solid #dee2e6;
  margin: 0.25rem 0;
}
.p-tieredmenu .p-submenu-icon {
  font-size: 0.875rem;
}

.p-inline-message {
  padding: 0.75rem 1rem;
  margin: 0;
  border-radius: 8px;
}
.p-inline-message.p-inline-message-info {
  background: #039BE5;
  border: solid #027cb7;
  border-width: 1px;
  color: #ffffff;
}
.p-inline-message.p-inline-message-info .p-inline-message-icon {
  color: #ffffff;
}
.p-inline-message.p-inline-message-success {
  background: #43A047;
  border: 0 none;
  border-width: 1px;
  color: #ffffff;
}
.p-inline-message.p-inline-message-success .p-inline-message-icon {
  color: #ffffff;
}
.p-inline-message.p-inline-message-warn {
  background: #FFB300;
  border: 0 none;
  border-width: 1px;
  color: #495057;
}
.p-inline-message.p-inline-message-warn .p-inline-message-icon {
  color: #495057;
}
.p-inline-message.p-inline-message-error {
  background: #E53935;
  border: 0 none;
  border-width: 1px;
  color: #ffffff;
}
.p-inline-message.p-inline-message-error .p-inline-message-icon {
  color: #ffffff;
}
.p-inline-message .p-inline-message-icon {
  font-size: 1rem;
  margin-right: 0.5rem;
}
.p-inline-message .p-inline-message-text {
  font-size: 1rem;
}
.p-inline-message.p-inline-message-icon-only .p-inline-message-icon {
  margin-right: 0;
}

.p-message {
  margin: 1rem 0;
  border-radius: 8px;
}
.p-message .p-message-wrapper {
  padding: 1rem 1.5rem;
}
.p-message .p-message-close {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background: transparent;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.p-message .p-message-close:hover {
  background: rgba(255, 255, 255, 0.3);
}
.p-message .p-message-close:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #bfd1f6;
}
.p-message.p-message-info {
  background: #039BE5;
  border: solid #027cb7;
  border-width: 0 0 0 4px;
  color: #ffffff;
}
.p-message.p-message-info .p-message-icon {
  color: #ffffff;
}
.p-message.p-message-info .p-message-close {
  color: #ffffff;
}
.p-message.p-message-success {
  background: #43A047;
  border: 0 none;
  border-width: 0 0 0 4px;
  color: #ffffff;
}
.p-message.p-message-success .p-message-icon {
  color: #ffffff;
}
.p-message.p-message-success .p-message-close {
  color: #ffffff;
}
.p-message.p-message-warn {
  background: #FFB300;
  border: 0 none;
  border-width: 0 0 0 4px;
  color: #495057;
}
.p-message.p-message-warn .p-message-icon {
  color: #495057;
}
.p-message.p-message-warn .p-message-close {
  color: #495057;
}
.p-message.p-message-error {
  background: #E53935;
  border: 0 none;
  border-width: 0 0 0 4px;
  color: #ffffff;
}
.p-message.p-message-error .p-message-icon {
  color: #ffffff;
}
.p-message.p-message-error .p-message-close {
  color: #ffffff;
}
.p-message .p-message-text {
  font-size: 1rem;
  font-weight: 500;
}
.p-message .p-message-icon {
  font-size: 1.5rem;
  margin-right: 0.5rem;
}
.p-message .p-message-summary {
  font-weight: 700;
}
.p-message .p-message-detail {
  margin-left: 0.5rem;
}

.p-toast {
  opacity: 0.9;
}
.p-toast .p-toast-message {
  margin: 0 0 1rem 0;
  box-shadow: 0 3px 14px 0 rgba(0, 0, 0, 0.3);
  border-radius: 8px;
}
.p-toast .p-toast-message .p-toast-message-content {
  padding: 1rem;
  border-width: 0 0 0 4px;
}
.p-toast .p-toast-message .p-toast-message-content .p-toast-message-text {
  margin: 0 0 0 1rem;
}
.p-toast .p-toast-message .p-toast-message-content .p-toast-message-icon {
  font-size: 2rem;
}
.p-toast .p-toast-message .p-toast-message-content .p-toast-summary {
  font-weight: 700;
}
.p-toast .p-toast-message .p-toast-message-content .p-toast-detail {
  margin: 0.5rem 0 0 0;
}
.p-toast .p-toast-message .p-toast-icon-close {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background: transparent;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.p-toast .p-toast-message .p-toast-icon-close:hover {
  background: rgba(255, 255, 255, 0.3);
}
.p-toast .p-toast-message .p-toast-icon-close:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #bfd1f6;
}
.p-toast .p-toast-message.p-toast-message-info {
  background: #039BE5;
  border: solid #027cb7;
  border-width: 0 0 0 4px;
  color: #ffffff;
}
.p-toast .p-toast-message.p-toast-message-info .p-toast-message-icon,
.p-toast .p-toast-message.p-toast-message-info .p-toast-icon-close {
  color: #ffffff;
}
.p-toast .p-toast-message.p-toast-message-success {
  background: #43A047;
  border: 0 none;
  border-width: 0 0 0 4px;
  color: #ffffff;
}
.p-toast .p-toast-message.p-toast-message-success .p-toast-message-icon,
.p-toast .p-toast-message.p-toast-message-success .p-toast-icon-close {
  color: #ffffff;
}
.p-toast .p-toast-message.p-toast-message-warn {
  background: #FFB300;
  border: 0 none;
  border-width: 0 0 0 4px;
  color: #495057;
}
.p-toast .p-toast-message.p-toast-message-warn .p-toast-message-icon,
.p-toast .p-toast-message.p-toast-message-warn .p-toast-icon-close {
  color: #495057;
}
.p-toast .p-toast-message.p-toast-message-error {
  background: #E53935;
  border: 0 none;
  border-width: 0 0 0 4px;
  color: #ffffff;
}
.p-toast .p-toast-message.p-toast-message-error .p-toast-message-icon,
.p-toast .p-toast-message.p-toast-message-error .p-toast-icon-close {
  color: #ffffff;
}

.p-galleria .p-galleria-close {
  margin: 0.5rem;
  background: transparent;
  color: #ebedef;
  width: 4rem;
  height: 4rem;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  border-radius: 50%;
}
.p-galleria .p-galleria-close .p-galleria-close-icon {
  font-size: 2rem;
}
.p-galleria .p-galleria-close:hover {
  background: rgba(255, 255, 255, 0.1);
  color: #ebedef;
}
.p-galleria .p-galleria-item-nav {
  background: rgba(0, 0, 0, 0.2);
  color: #aeb6bf;
  width: 4rem;
  height: 4rem;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  border-radius: 8px;
  margin: 0.5rem 0;
}
.p-galleria .p-galleria-item-nav .p-galleria-item-prev-icon,
.p-galleria .p-galleria-item-nav .p-galleria-item-next-icon {
  font-size: 2rem;
}
.p-galleria .p-galleria-item-nav:not(.p-disabled):hover {
  background: rgba(0, 0, 0, 0.3);
  color: #ebedef;
}
.p-galleria .p-galleria-caption {
  background: rgba(0, 0, 0, 0.5);
  color: #ebedef;
  padding: 1rem;
}
.p-galleria .p-galleria-indicators {
  padding: 1rem;
}
.p-galleria .p-galleria-indicators .p-galleria-indicator button {
  background-color: #e9ecef;
  width: 1rem;
  height: 1rem;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  border-radius: 50%;
}
.p-galleria .p-galleria-indicators .p-galleria-indicator button:hover {
  background: #dee2e6;
}
.p-galleria .p-galleria-indicators .p-galleria-indicator.p-highlight button {
  background: #1f88ca;
  color: #ffffff;
}
.p-galleria.p-galleria-indicators-bottom .p-galleria-indicator, .p-galleria.p-galleria-indicators-top .p-galleria-indicator {
  margin-right: 0.5rem;
}
.p-galleria.p-galleria-indicators-left .p-galleria-indicator, .p-galleria.p-galleria-indicators-right .p-galleria-indicator {
  margin-bottom: 0.5rem;
}
.p-galleria.p-galleria-indicator-onitem .p-galleria-indicators {
  background: rgba(0, 0, 0, 0.5);
}
.p-galleria.p-galleria-indicator-onitem .p-galleria-indicators .p-galleria-indicator button {
  background: rgba(255, 255, 255, 0.4);
}
.p-galleria.p-galleria-indicator-onitem .p-galleria-indicators .p-galleria-indicator button:hover {
  background: rgba(255, 255, 255, 0.6);
}
.p-galleria.p-galleria-indicator-onitem .p-galleria-indicators .p-galleria-indicator.p-highlight button {
  background: #1f88ca;
  color: #ffffff;
}
.p-galleria .p-galleria-thumbnail-container {
  background: rgba(0, 0, 0, 0.9);
  padding: 1rem 0.25rem;
}
.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-prev,
.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-next {
  margin: 0.5rem;
  background-color: transparent;
  color: #aeb6bf;
  width: 2rem;
  height: 2rem;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  border-radius: 50%;
}
.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-prev:hover,
.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-next:hover {
  background: rgba(255, 255, 255, 0.1);
  color: #aeb6bf;
}
.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-item-content {
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-item-content:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #bfd1f6;
}

.p-galleria-mask.p-component-overlay {
  background-color: rgba(0, 0, 0, 0.9);
}

.p-inplace .p-inplace-display {
  padding: 0.75rem 1rem;
  border-radius: 8px;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.p-inplace .p-inplace-display:not(.p-disabled):hover {
  background: #e9ecef;
  color: #495057;
}
.p-inplace .p-inplace-display:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #bfd1f6;
}

.p-progressbar {
  border: 0 none;
  height: 1.5rem;
  background: #dee2e6;
  border-radius: 8px;
}
.p-progressbar .p-progressbar-value {
  border: 0 none;
  margin: 0;
  background: #1f88ca;
}
.p-progressbar .p-progressbar-label {
  color: #495057;
  line-height: 1.5rem;
}

.p-terminal {
  background: #ffffff;
  color: #495057;
  border: 1px solid #dee2e6;
  padding: 1rem;
}
.p-terminal .p-terminal-input {
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
}

.p-blockui.p-component-overlay {
  background: rgba(0, 0, 0, 0.4);
}

.p-badge {
  background: #1f88ca;
  color: #ffffff;
  font-size: 0.75rem;
  font-weight: 700;
  min-width: 1.5rem;
  height: 1.5rem;
  line-height: 1.5rem;
}
.p-badge.p-badge-secondary {
  background-color: #bbb;
  color: #ffffff;
}
.p-badge.p-badge-success {
  background-color: #4caf50;
  color: #ffffff;
}
.p-badge.p-badge-info {
  background-color: #03a9f4;
  color: #ffffff;
}
.p-badge.p-badge-warning {
  background-color: #ffc107;
  color: #495057;
}
.p-badge.p-badge-danger {
  background-color: #f44336;
  color: #ffffff;
}
.p-badge.p-badge-lg {
  font-size: 1.125rem;
  min-width: 2.25rem;
  height: 2.25rem;
  line-height: 2.25rem;
}
.p-badge.p-badge-xl {
  font-size: 1.5rem;
  min-width: 3rem;
  height: 3rem;
  line-height: 3rem;
}

.p-tag {
  background: #1f88ca;
  color: #ffffff;
  font-size: 0.75rem;
  font-weight: 700;
  padding: 0.25rem 0.4rem;
  border-radius: 8px;
}
.p-tag.p-tag-success {
  background-color: #4caf50;
  color: #ffffff;
}
.p-tag.p-tag-info {
  background-color: #03a9f4;
  color: #ffffff;
}
.p-tag.p-tag-warning {
  background-color: #ffc107;
  color: #495057;
}
.p-tag.p-tag-danger {
  background-color: #f44336;
  color: #ffffff;
}

/* Customizations to the designer theme should be defined here */
body {
  background-color: #edf1f5;
  font-family: "Open Sans", sans-serif;
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: inherit;
  margin-top: inherit;
}

h1 {
  font-size: 1.75rem;
}

h2 {
  font-size: 1.5rem;
}

h3 {
  font-size: 1.15rem;
}

hr {
  border-top: 1px solid #ebebeb;
  border-bottom: none;
}

.font-14 {
  font-size: 14px !important;
}

a {
  text-decoration: none;
  color: var(--primary-color);
}
a:hover {
  text-decoration: underline;
}

.panel {
  background: white;
  border-radius: 8px;
  padding: 2em;
  box-shadow: 0 9px 13px rgba(0, 0, 0, 0.05);
}
.panel.less-padding {
  padding: 1rem 1.5rem;
}

p-card.less-padding .p-card-body {
  padding: 0.5rem 1.5rem;
}

.p-card {
  position: relative;
}
.p-card hr {
  margin: 1.5rem -1.5rem;
}

.site {
  margin-left: auto;
  margin-right: auto;
  margin-top: 146px;
  max-width: 1140px;
}
@media screen and (max-width: 1330px) {
  .site {
    margin-left: 95px;
    margin-right: inherit;
  }
}
@media screen and (max-width: 768px) {
  .site {
    margin-left: 0;
    margin-bottom: 70px;
  }
}

.header {
  height: 70px;
  width: 100%;
  left: 0;
  top: 0;
  background: white;
  position: fixed;
  box-shadow: 0 9px 13px rgba(0, 0, 0, 0.05);
  z-index: 2;
  padding: 14px 25px 14px 95px;
}
@media screen and (max-width: 768px) {
  .header {
    padding-left: 25px;
  }
}
.header .logo {
  height: 42px;
}
.header .logo img {
  height: 42px;
}

.sub-header {
  background: var(--primary-color);
  min-height: 56px;
  position: fixed;
  top: 70px;
  left: 0;
  width: 100%;
  z-index: 1;
  box-shadow: 0 9px 13px rgba(0, 0, 0, 0.05);
}
.sub-header .container {
  padding: 0 10px;
  margin-left: 70px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 1140px;
}
@media screen and (max-width: 1330px) {
  .sub-header .container {
    margin-left: 95px;
    margin-right: inherit;
  }
}
@media screen and (max-width: 768px) {
  .sub-header .container {
    margin-left: 0;
  }
}
.sub-header .container h3 {
  margin: 0;
  color: white;
  font-size: 1rem;
}
.sub-header .container .p-button.p-button-sm {
  font-size: 1rem;
}
.sub-header .container .p-button.p-button-sm,
.sub-header .container .p-button {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.sub-header .container .p-splitbutton .p-splitbutton-defaultbutton {
  padding-left: 2.5rem;
  padding-right: 2.25rem;
}

.btn-profile {
  padding: 0;
  height: 42px;
  width: 42px;
  font-weight: 600;
  font-size: 0.875em;
  line-height: 42px;
}

.left-nav {
  display: flex;
  height: 100vh;
  width: 70px;
  background: white;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  box-shadow: 0 9px 13px rgba(0, 0, 0, 0.05);
}
.left-nav .items {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 0.5rem;
}
.left-nav .items .p-button {
  margin: 0.75rem 0;
  font-size: 1.35rem;
}
.left-nav .items .p-button.p-button-text {
  color: var(--color-light-grey);
}
.left-nav .items .p-button.p-button-text.p-button-plain {
  color: var(--primary-color);
}

@media screen and (max-width: 768px) {
  .left-nav {
    z-index: 99;
    bottom: 0;
    top: inherit;
    width: 100%;
    height: 70px;
    box-shadow: 0 -3px 8px rgba(0, 0, 0, 0.07);
  }
  .left-nav .items {
    justify-content: space-between;
    flex-direction: row;
    padding: 0 2em;
    width: 100%;
  }
}
.info-label {
  margin: 0.5em 0;
}
.info-label label {
  text-transform: uppercase;
  color: var(--color-light-grey);
  font-size: 0.9em;
  display: block;
}

.breadcrumb .p-button {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
}
.breadcrumb .p-button-label {
  font-size: 1.5em;
  color: initial;
}
.breadcrumb .p-button .p-button-icon-left {
  margin-right: 1rem;
}

.p-button:disabled {
  opacity: 0.4;
}

.p-button.p-button-white,
.p-buttonset.p-button-white > .p-button,
.p-splitbutton.p-button-white > .p-button {
  background: white;
  color: #31363b;
  box-shadow: 0px 1px 2px -1px #0000009e;
}
.p-button.p-button-white:enabled:hover,
.p-buttonset.p-button-white > .p-button:enabled:hover,
.p-splitbutton.p-button-white > .p-button:enabled:hover {
  color: var(--primary-color);
  background: white;
}
.p-button.p-button-white:disabled, .p-button.p-button-white.p-disabled,
.p-buttonset.p-button-white > .p-button:disabled,
.p-buttonset.p-button-white > .p-button.p-disabled,
.p-splitbutton.p-button-white > .p-button:disabled,
.p-splitbutton.p-button-white > .p-button.p-disabled {
  opacity: 0.5;
}

.work-order-dialog {
  width: 100%;
  max-width: 1000px;
}

.p-grid.p-d-flex.p-ai-center.quote-preview {
  background: #f5f7f9;
  border-radius: 8px;
  padding: 0 0.5em;
  margin: 0.5em 0;
  font-size: 0.8rem;
}
.p-grid.p-d-flex.p-ai-center.quote-preview:hover {
  cursor: pointer;
}
.p-grid.p-d-flex.p-ai-center.quote-preview.has-discount {
  background: #f7d7d7;
}
.p-grid.p-d-flex.p-ai-center.quote-preview.has-extra-fee {
  background: #daf7d7;
}
.p-grid.p-d-flex.p-ai-center.quote-preview strong {
  font-weight: 600;
}
.p-grid.p-d-flex.p-ai-center.quote-preview .p-button.p-button-icon-only.p-button-rounded {
  height: 1.675rem;
  width: 1.675rem;
}

.p-panel-title {
  font-size: 2em;
}

.p-button.p-button-icon-only.p-button-rounded.button-add {
  width: 3.5rem;
  border-radius: 20px;
  height: 2rem;
  font-size: 1rem;
  line-height: 1;
}
.p-button.p-button-icon-only.p-button-rounded.button-add .pi {
  font-size: 0.85rem;
  font-weight: bold;
}

.p-button .p-badge {
  position: absolute;
  top: 0;
  display: block;
  border-radius: 999px;
  margin: 0;
  right: 0;
}

.ui-dialog-footer,
.p-dialog .p-dialog-footer,
.ui-form-footer {
  position: absolute;
  margin: 1.5rem -1.5rem -1.5rem -1.5rem;
  padding: 1rem 1.5rem;
  box-shadow: 0 -3px 13px rgba(0, 0, 0, 0.075);
  bottom: 1.5rem;
  z-index: 2;
  width: 100%;
  background: white;
  height: 80px;
}

.p-dialog .p-dialog-footer {
  bottom: 1rem;
  margin-left: 0;
}

.ui-form-footer {
  position: inherit;
  width: inherit;
  margin-top: 2.5rem;
}

button.p-button-icon-only.p-button-rounded.p-button-sm {
  height: 1.85rem;
  width: 1.85rem;
  padding: 0;
}

.p-steps .p-steps-item .p-menuitem-link .p-steps-title {
  font-size: 0.9em;
}
.p-steps .p-steps-item.p-highlight .p-steps-number {
  border-color: var(--primary-color);
}
.p-steps .p-steps-item.p-highlight .p-steps-title {
  color: var(--primary-color);
}

.p-menu .menu-item-success .p-menuitem-link .p-menuitem-icon {
  color: #3cad3c;
}

.p-menu .menu-item-danger .p-menuitem-link .p-menuitem-icon {
  color: red;
}

.ui-state-complete .ui-steps-number {
  background-color: green !important;
}

.work-order-steps {
  margin: 2rem 0;
}

.disabled-form {
  opacity: 0.25;
}

.work-order-list .p-card-body {
  padding: 0.5rem 1.5rem;
}
.work-order-list hr {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.discount {
  color: #c63737;
}

.extra {
  color: #75ab55;
}

.work-order-summary h3 {
  margin-bottom: 0.5rem;
}
.work-order-summary .label,
.work-order-summary .total {
  padding: 0.4rem 0;
}
.work-order-summary .label {
  color: #808080;
}
.work-order-summary .total {
  font-weight: 600;
}
.work-order-summary .total.discount {
  color: #c63737;
}
.work-order-summary .total.extra {
  color: #75ab55;
}
.work-order-summary .total-price {
  background: var(--primary-color);
  margin: -1.5rem;
  padding: 0.9rem 1.5rem;
  color: white;
  font-size: 1.15rem;
  border-radius: 0 0 8px 8px;
}
.work-order-summary .total-price .label,
.work-order-summary .total-price .total {
  color: white;
}
@media print {
  .work-order-summary .total-price .label,
.work-order-summary .total-price .total {
    color: black;
  }
}

.status-indicator {
  text-transform: uppercase;
  font-size: 0.8rem;
  font-weight: 700;
}
.status-indicator i {
  display: inline-block;
  height: 8px;
  width: 8px;
  border-radius: 100px;
  margin-right: 0.15rem;
}
.status-indicator.draft {
  color: var(--status-color-draft);
}
.status-indicator.draft i {
  background-color: var(--status-color-draft);
}
.status-indicator.scheduled {
  color: var(--status-color-scheduled);
}
.status-indicator.scheduled i {
  background-color: var(--status-color-scheduled);
}
.status-indicator.complete {
  color: var(--status-color-complete);
}
.status-indicator.complete i {
  background-color: var(--status-color-complete);
}

.work-order-preview .p-card-body {
  padding: 0.5rem 1.5rem;
}
.work-order-preview .work-order-number {
  color: var(--color-light-grey);
}
.work-order-preview .icon {
  color: var(--color-light-grey);
}

.calendar-status {
  border-radius: 6px;
  width: 45px;
  text-align: center;
  border-width: 1px;
  border-style: solid;
}
.calendar-status .month {
  color: white;
  text-transform: uppercase;
  font-size: 0.75rem;
  padding: 1px 0;
}
.calendar-status .day {
  font-weight: 600;
  font-size: 1rem;
  padding: 5px 0;
  line-height: 1;
}
.calendar-status.draft {
  border-color: var(--status-color-draft);
}
.calendar-status.draft .month {
  background: var(--status-color-draft);
}
.calendar-status.scheduled {
  border-color: var(--status-color-scheduled);
}
.calendar-status.scheduled .month {
  background: var(--status-color-scheduled);
}
.calendar-status.complete {
  border-color: var(--status-color-complete);
}
.calendar-status.complete .month {
  background: var(--status-color-complete);
}

.p-button.elipsis {
  font-size: 2rem;
  color: var(--color-light-grey);
}

.map {
  margin-bottom: -1.5rem;
  border-radius: 0 8px 0 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.color-grey {
  color: var(--color-light-grey);
}

.color-primary {
  color: var(--primary-color);
}

.customer-card:hover {
  cursor: pointer;
}
.customer-card .p-card .p-card-body {
  padding: 1rem 1rem 0.5rem;
}
.customer-card .p-grid {
  font-size: 0.8rem;
}

.p-datatable .ui-column-data {
  display: inline-flex;
  word-break: break-all;
}

.p-datatable .p-datatable-thead > tr > th,
.p-datatable .p-datatable-tbody > tr > td {
  font-size: 0.9em;
}

.p-datatable .p-datatable-thead > tr.sort-options > th {
  padding: 0.5em 0.35em;
  background: none;
}
.p-datatable .p-datatable-thead > tr.sort-options > th .p-inputtext {
  height: 36px;
  line-height: 1;
  font-size: 1em;
}

.maintenance-table .p-datatable {
  border: 1px solid #ebebeb;
}
.maintenance-table .p-datatable .p-datatable-header {
  background: none;
  padding: 0 0 1.5rem 0;
}
.maintenance-table .ui-column-title {
  display: none;
}

/* Responsive */
@media screen and (max-width: 640px) {
  .maintenance-table .p-datatable .p-datatable-thead > tr > th,
.maintenance-table .p-datatable .p-datatable-tfoot > tr > td {
    display: none !important;
  }
  .maintenance-table .p-datatable colgroup {
    display: none;
  }
  .maintenance-table .p-datatable .p-datatable-tbody > tr > td {
    text-align: left;
    display: block;
    border: 0 none !important;
    width: 100% !important;
    float: left;
    clear: left;
    border: 0 none;
    padding: 1rem 0 0;
  }
  .maintenance-table .p-datatable .p-datatable-tbody > tr > td:last-child {
    padding-bottom: 1rem;
  }
  .maintenance-table .p-datatable .p-datatable-tbody > tr > td .ui-column-title {
    padding: 0.4em 1em;
    width: 35%;
    display: inline-block;
    margin: -0.4em 1em -0.4em -0.4em;
    font-weight: bold;
  }
  .maintenance-table .p-datatable .p-datatable-tbody > tr > td .ui-column-data {
    display: inline-flex;
    word-break: break-all;
  }
}
.status-badge {
  border-radius: 6px;
  padding: 0.5em 1em;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.25px;
  white-space: nowrap;
  display: inline-block;
  text-align: center;
}
.status-badge.status-Called, .status-badge.status-Scheduled {
  background-color: var(--status-color-scheduled);
  color: #256029;
}
.status-badge.status-Draft, .status-badge.status-Not_Set {
  background-color: var(--status-color-draft);
  color: #6b7a8a;
}
.status-badge.status-Complete {
  background-color: #cce5f5;
  color: var(--status-color-complete);
}
.status-badge.status-Past_Due {
  background-color: #ffcdd2;
  color: #c63737;
}
.status-badge.status-Not_Called, .status-badge.status-Archived {
  background-color: #feedaf;
  color: #8a5340;
}

body {
  -webkit-print-color-adjust: exact !important;
  print-color-adjust: exact !important;
}

label {
  cursor: inherit;
}

.p-inputtext,
.p-dropdown,
.p-autocomplete {
  width: 100%;
}

.p-field > label {
  display: inline-block;
  margin-bottom: 0.5rem;
  color: #969696;
}

.spinner .ui-progress-spinner .ui-progress-spinner-svg .ui-progress-spinner-circle {
  animation: ui-progress-spinner-dash 1.5s ease-in-out infinite;
}

.p-dialog.p-confirm-dialog .p-confirm-dialog-message {
  margin-left: 0;
}

.responsive-dialog {
  width: 50vw;
}

.p-dialog .p-dialog-header .p-dialog-title {
  font-size: 1.25rem;
}

@media screen and (max-width: 70em) {
  .responsive-dialog {
    width: 75vw;
  }
}
@media screen and (max-width: 40em) {
  .responsive-dialog {
    width: 100vw;
  }
}
.p-datatable .p-datatable-tbody > tr.has-discount {
  background: #f7d7d7;
}

.p-datatable .p-datatable-tbody > tr.has-extra-fee {
  background: #daf7d7;
}

@media screen and (max-width: 40em) {
  .p-datatable.p-datatable-responsive .p-datatable-tbody > tr > td .ui-column-title {
    padding: 0.4em;
    min-width: 33%;
    display: inline-block;
    margin: -0.4em 1em -0.4em -0.4em;
    font-weight: bold;
  }

  .p-datatable.p-datatable-responsive .p-datatable-tbody > tr > td {
    padding: 0.35em 1em;
    font-size: 12px;
  }

  .p-datatable .p-datatable-tbody > tr {
    border-top: 1px solid #e9ecef;
  }
}
@media screen and (min-width: 40em) {
  .p-datatable.p-datatable-responsive .p-datatable-tbody > tr > td .ui-column-title {
    display: none;
  }
}
.p-paginator .p-dropdown {
  height: 36px;
  line-height: 1;
  font-size: 0.875em;
}

.p-calendar {
  width: 100%;
}
.p-calendar .p-datepicker {
  min-width: inherit !important;
}

.p-splitbutton .p-splitbutton-defaultbutton {
  border-right: 1px solid white !important;
  padding-left: 3.5rem;
  padding-right: 3.25rem;
}

.p-button.p-button-sm .p-button-icon {
  font-size: 1rem;
}

.p-button-icon.p-button-icon-left.far.far, .p-button-icon.p-button-icon-left.far.fal {
  position: relative;
  top: 1px;
}

fieldset {
  display: block;
  margin: unset;
  padding: unset;
  border: unset;
}

.p-menu .p-menuitem-link .p-menuitem-icon {
  margin-top: 1px;
  font-size: 1rem;
  width: 20px;
  text-align: center;
}

.fa-ellipsis-v {
  position: relative;
  top: 2px;
}

.p-toast-detail {
  word-break: break-all;
}

.color-white {
  color: white;
}

.full-width {
  width: 100%;
}

.no-overflow {
  overflow: inherit;
}

.flex-grow-1 {
  flex-grow: 1;
}

.print-only,
.print-only-inline {
  display: none;
}

.print-p-col-6 {
  width: 100%;
}

@media print {
  .p-print-none {
    display: none !important;
  }

  .print-only {
    display: block;
  }

  .print-only-inline {
    display: inline-block;
  }

  .print-p-col-6 {
    width: 50%;
  }

  .print-p-col-3 {
    width: 25%;
  }

  .print-p-col-9 {
    width: 75%;
  }

  .pagebreak {
    clear: both;
    page-break-after: always;
  }
}
@media print {
  .site {
    margin: 0;
    max-width: none;
  }

  .header,
.sub-header,
.left-nav,
.p-menu {
    display: none !important;
  }

  .p-card {
    box-shadow: none;
    border: 1px solid black;
    border-radius: 0;
  }
}
.login-background {
  background: #1a275f;
  background: linear-gradient(135deg, #1a275f 0%, #1c4d88 100%);
  height: 100vh;
  width: 100vw;
}

.login {
  height: 100vh;
  margin: 0;
}
.login .login-container {
  max-width: 420px;
  width: 100%;
}
.login .p-field > label {
  font-size: 0.75rem;
  text-transform: uppercase;
  color: #ffffffa1;
  letter-spacing: 0.35px;
}
.login .p-inputtext {
  background: #ffffff26;
  border: none;
  color: white;
  font-weight: 400;
}
.login .p-inputtext:hover, .login .p-inputtext:active, .login .p-inputtext:focus {
  background: white;
  color: black;
}